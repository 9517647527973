import React from 'react'

import { withCookies } from 'react-cookie'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import Component from 'app/components/Component'
import RichText from 'app/components/RichText/RichText'
import ProductOfferModel from 'app/models/ProductOffer/ProductOffer'

import './PriceInformation.scss'

// Component model definition
export class PriceInformationModel extends Model {
  constructor(d) {
    super(d)
    this.productOffer = new ProductOfferModel(d.productOffer)
  }

  static props() {
    return {
      productOffer: {
        type: ProductOfferModel.shape,
        default: ProductOfferModel.defaultProps()
      }
    }
  }
}

// Component definition
export class PriceInformation extends Component {
  constructor(props) {
    super(props, 'price-information')
    this.province = props.cookies ? props.cookies.get('queryLocation') : ''
  }

  renderPromoBanner() {
    const { isActivePromo, pricingText } = this.props.productOffer

    if (!pricingText) {
      return null
    }

    const promoBannerText =
      pricingText.promoBannerTextHtml || pricingText.promoBannerText
    if (!promoBannerText) {
      return null
    }
    const flag = (
      <div className={this.e('limited-offer-flag-container')}>
        <div className={this.e('limited-offer-flag')}>
          <RichText
            isMarkdown
            className={this.e('limited-offer-text')}
            markup={promoBannerText}
          />
        </div>
      </div>
    )

    return (
      <div className={this.e('limited-offer-flag-block')}>
        {isActivePromo && flag}
      </div>
    )
  }

  renderPrices() {
    const {
      pricing,
      pricingText: { customDisclaimer }
    } = this.props.productOffer
    const modifier = {}
    modifier[`${pricing.length}-prices`] = true

    if (!pricing.length) {
      return null
    }

    const prices = pricing.reduce((accumulator, price, i) => {
      const regionalPrice = price[this.province] ? price[this.province] : price
      let priceBlock = this.renderPriceBlock(regionalPrice, customDisclaimer, i)
      if (i > 0) {
        const divider = <span key={`span-${i}`} className={this.e('divider')} />
        return accumulator.concat([divider, priceBlock])
      }
      return accumulator.concat(priceBlock)
    }, [])

    return <div className={this.e('price-wrapper', modifier)}>{prices}</div>
  }

  renderPriceBlock(priceBug, customDisclaimer, i) {
    const bold = i === 0
    const priceDollars = priceBug.price.split('.')[0]
    const priceCents = priceBug.price.split('.')[1]
    return (
      <div key={uuid()} className={this.e('price-block', { bold })}>
        <p className={this.e('price-block__price')}>
          <span className={this.e('price-block__price-symbol')}>$</span>
          <span>{priceDollars}</span>
          <span className={this.e('price-block__cents-per-container')}>
            <span className={this.e('price-block__price-cents')}>
              {priceCents}
            </span>
            <span className={this.e('price-block__per-text')}>
              {`/mo`}
              {bold && (
                <RichText
                  markup={customDisclaimer}
                  renderers={{ root: 'span' }}
                />
              )}
            </span>
          </span>
        </p>
        {priceBug.text && (
          <RichText
            className={this.e('price-block__text-below')}
            markup={priceBug.text}
            renderers={{ root: 'div' }}
          />
        )}
      </div>
    )
  }

  renderNewCustomerText() {
    const { isActivePromo, pricingText } = this.props.productOffer

    if (!pricingText) {
      return null
    }

    const span = (
      <RichText
        className={this.e('new-customer')}
        markup={pricingText.newCustomerText}
        renderers={{ root: 'span' }}
      />
    )

    return (
      <div className={this.e('new-customer-block')}>
        {isActivePromo && span}
      </div>
    )
  }
  renderStartingAtText() {
    const { pricingText } = this.props.productOffer

    if (!pricingText || !pricingText.startingAtText) {
      return null
    }

    const span = (
      <span className={this.e('starting-at')}>
        {pricingText.startingAtText}
      </span>
    )

    return <div className={this.e('starting-at-block')}>{span}</div>
  }

  render() {
    const { pricingText } = this.props.productOffer
    const helperClass =
      pricingText && pricingText.startingAtText ? 'is-bundle' : ''
    return (
      <div className={this.b(helperClass)}>
        {this.renderPromoBanner()}
        {this.renderStartingAtText()}
        {this.renderPrices()}
        {this.renderNewCustomerText()}
      </div>
    )
  }
}

PriceInformation.props(PriceInformationModel)
export default withCookies(PriceInformation)
