import React from 'react'

import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Card from 'app/components/Card/Card'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import { IconModel } from 'app/components/Icon/Icon'
import RichText from 'app/components/RichText/RichText'
import { DisclaimerCta } from '@shaw/react-component-library'
import ProductAddOnModel from 'app/models/ProductAddOn/ProductAddOn'

import './ProductAddOnCard.scss'

// Component model definition
export class ProductAddOnCardModel extends Model {
  constructor(d, parentName = false) {
    super(d)

    this.icon = d.icon ? new IconModel(d.icon) : undefined
    this.productAddOn = d.productAddOn
      ? new ProductAddOnModel(d.productAddOn)
      : undefined
    this.cta = d.cta ? new LinkModel(d.cta) : undefined

    // Computed
    this.analyticsEvent = null
    this.analyticsValue = null
    if (this.cta) {
      this.analyticsEvent = this.cta.determineAnalyticsEvent()
      this.analyticsValue = parentName
        ? `${slug(parentName)}|${slug(d.name)}|${slug(this.cta.text)}`
        : `${slug(d.name)}|${slug(this.cta.text)}`
    }
  }

  static props() {
    return {
      icon: {
        type: IconModel.shape,
        default: null
      },
      title: String,
      productAddOn: {
        type: ProductAddOnModel.shape.isRequired,
        default: ProductAddOnModel.defaultProps()
      },
      cta: {
        type: LinkModel.shape,
        default: LinkModel.defaultProps()
      },
      analyticsEvent: String,
      analyticsValue: String,
      superscript: String
    }
  }
}

// Component definition
export default function ProductAddOnCard(
  props = ProductAddOnCardModel.defaultProps()
) {
  const b = Bem('product-add-on-card')
  return (
    <div className={b.m({ scotch: true }).and(props.className).classes()}>
      <Card icon={props.icon}>
        <div className={b.e('name').classes()}>
          <RichText markup={props.title || props.productAddOn.productName} />
        </div>
        <div className={b.e('description').classes()}>
          <RichText markup={props.productAddOn.shortDescription} />
        </div>
        {props.productAddOn.price && (
          <div className={b.e('price-block').classes()}>
            {props.productAddOn.pricePrefix && (
              <span className={b.e('price-prefix').classes()}>
                {props.productAddOn.pricePrefix}
              </span>
            )}

            <p className={b.e('price').classes()}>
              <sup className={b.e('price-symbol').classes()}>$</sup>
              {props.productAddOn.price}
              <span className={b.e('per-text').classes()}>
                /mo
                <DisclaimerCta
                  href={props.superscript}
                  className={b.e('asterisk-symbol').classes()}
                >
                  {props.superscript}
                </DisclaimerCta>
              </span>
            </p>

            {props.productAddOn?.priceSuffix && (
              <span className={b.e('price-suffix').classes()}>
                {props.productAddOn?.priceSuffix}
              </span>
            )}
          </div>
        )}

        {props.cta?.href && (
          <div className={b.e('button').classes()}>
            <Button
              {...props.cta.asButton}
              analyticsEvent={props.cta.analyticsEvent || props.analyticsEvent}
              analyticsValue={props.analyticsValue}
            />
          </div>
        )}
      </Card>
    </div>
  )
}

ProductAddOnCard.propTypes = {
  icon: PropTypes.shape(),
  title: PropTypes.string,
  productAddOn: PropTypes.shape().isRequired,
  cta: PropTypes.shape(),
  analyticsEvent: PropTypes.string,
  analyticsValue: PropTypes.string,
  superscript: PropTypes.string
}

