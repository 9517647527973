import React from 'react'

import PropTypes from 'prop-types'

import omit from 'lodash/omit'
import Model from 'app/models/Model'
import Icon from 'app/components/Icon/Icon'
import Component from 'app/components/Component'
import ContentLinkModel from 'app/models/Link/Link'
import RichText from 'app/components/RichText/RichText'
import ResponsiveImage from 'app/components/ResponsiveImage/ResponsiveImage'

import './Link.scss'
export class LinkModel extends Model {
  static props() {
    return {
      link: [ContentLinkModel.shape, String],
      tag: PropTypes.node,
      target: [String, Object],
      title: String,
      onFocus: { type: Function, default: () => {} },
      onBlur: { type: Function, default: () => {} },
      onClick: { type: Function, default: () => {} },
      onMouseOver: { type: Function, default: () => {} },
      onMouseOut: { type: Function, default: () => {} },
      tabIndex: String,
      useChildren: { type: Boolean, default: false },
      analyticsValue: String,
      analyticsEvent: String,
      inherit: { type: Boolean, default: true },
      activeRoute: String,
      'data-tooltip-id': String,
      'data-tooltip-content': String,
      'data-tooltip-place': String
    }
  }
}

const truthy = true

// Module component definition
export default class Link extends Component {
  constructor(props) {
    super(props, 'link')
    this.state = {
      url: ''
    }
  }

  componentDidMount() {
    this.setState({ url: location.pathname })
  }

  handleClick(e) {
    this.props.onClick(e)
  }

  m() {
    return {
      inherit: this.props.inherit
    }
  }

  render() {
    const link = new ContentLinkModel(this.props.link)

    const props = {
      className: this.b(),
      ...omit(this.props, ['className', 'children', 'link', 'tag', 'target'])
    }

    return this.props.tag
      ? this.renderTag(this.props.tag, link, props)
      : this.renderLink(link, props)
  }

  renderTag(tag, link, props) {
    return React.createElement(tag, props, this.renderLink(link))
  }

  renderLink(link, props = {}) {
    const { url } = this.state
    const activeRegexp = new RegExp(link.activeRoute)
    const isActive = url === link.url ? ' is-active' : ''
    const route = url.split('/')
    const isActiveRoute =
      link.activeRoute && activeRegexp.test(`/${route[1]}`) === true
        ? ' is-active'
        : ''
    const classes = `${props.className}${isActive}${isActiveRoute}`
    const restOfProps = omit(props, [
      'analyticsEvent',
      'analyticsValue',
      'useChildren',
      'inherit',
      'activeRoute'
    ])

    return (
      <a
        {...restOfProps}
        href={typeof link === 'string' ? link : link.url}
        onClick={this.handleClick}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onMouseOver={this.props.onMouseOver}
        onMouseOut={this.props.onMouseOut}
        tabIndex={this.props.tabIndex}
        target={this.props.target || link.targetWindow}
        aria-label={link.ariaLabel}
        data-event={this.props.analyticsEvent || link.determineAnalyticsEvent()}
        data-value={this.props.analyticsValue}
        data-alt-label={link.additionalLabel}
        title={props.title || link.tooltip}
        className={classes}
      >
        <div className={this.e('linkblock')}>
          {link.image && <ResponsiveImage {...link.image} />}

          {link.icon && <Icon {...link.icon} />}

          <span className={this.e('text', { hidden: !!link.image })}>
            {link.text && !this.props.useChildren ? (
              <RichText markup={link.text} isMarkdown={truthy} />
            ) : (
              this.props.children
            )}
          </span>
        </div>
      </a>
    )
  }
}

Link.props(LinkModel)
