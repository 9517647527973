import React, { useState, useEffect } from 'react'

import { withCookies } from 'react-cookie'

import MapPin from './MapPin'
import { getLocationQuery, getValidProvinces } from './constants'

import './RegionSelectorButton.scss'

export const RegionSelectorButton = ({
  cookies,
  provinces,
  isLoading,
  handleClick
}) => {
  const [open, setOpen] = useState(false)
  const [province, setProvince] = useState(null)

  const defaultLabel = 'Select location'
  const validProvinces = getValidProvinces(provinces)
  const selectedLocation = () => {
    const prov = validProvinces.find(item => item.value === province)
    return prov && prov.label ? prov.label : defaultLabel
  }

  useEffect(() => {
    setProvince(getLocationQuery(cookies))
  }, [])

  function clickHandler(e) {
    handleClick(e)
    setOpen(!open)
  }

  return (
    <button
      data-event="navigationAction"
      data-value="top-nav|location"
      className={`region-selector__location ${
        open
          ? 'region-selector__location--open'
          : 'region-selector__location--closed'
      }`}
      onClick={clickHandler}
    >
      <MapPin />
      <span className="region-selector__button-label">
        {isLoading ? 'Saving location...' : selectedLocation()}
      </span>
    </button>
  )
}

export default withCookies(RegionSelectorButton)
