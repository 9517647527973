import React from 'react'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import FormSet from 'app/components/FormSet/FormSet'
import FormField from 'app/components/FormField/FormField'
import FormDropdown from 'app/components/FormDropdown/FormDropdown'
import FormAddressAutocomplete from 'app/components/FormAddressAutocomplete/FormAddressAutocomplete'

import './FormFieldGroup.scss'

const FieldMap = {
  FormAddressAutocomplete,
  FormField,
  FormDropdown
}

// Component model definition
export class FormFieldGroupModel extends Model {
  static props() {
    return {
      columns: Number,
      fields: Array,
      form: Object,
      onFormChange: {
        type: Function,
        default: () => {}
      }
    }
  }
}

// Component definition
export default class FormFieldGroup extends Component {
  constructor(props) {
    super(props, 'form-field-group')
  }

  render() {
    return (
      <FormSet
        className={this.b()}
        items={this.props.fields}
        columns={this.props.columns}
        render={this.renderField}
        tight
      />
    )
  }

  renderField(field, index) {
    const props = {
      className: this.e('field'),
      form: this.props.form,
      key: index,
      onFormChange: this.props.onFormChange,
      ...field
    }

    return React.createElement(FieldMap[field.contentType], props)
  }
}

FormFieldGroup.props(FormFieldGroupModel)
