import React from 'react'

import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import FormSet from 'app/components/FormSet/FormSet'
import RichText from 'app/components/RichText/RichText'
import RadioButton, {
  RadioButtonModel
} from 'app/components/RadioButton/RadioButton'

import './RadioGroup.scss'

// Component model definition
export class RadioGroupModel extends Model {
  static props() {
    return {
      id: String,
      description: String,
      list: RadioButtonModel.arrayOf,
      default: RadioButtonModel.shape,
      columns: Number,
      required: Boolean,
      requiredError: String,
      layout: { type: String, default: 'default' },
      hideIfAnswered: Boolean,
      displayIfIdProvidedInUrl: Boolean,
      errors: Object,
      onFormChange: {
        type: Function,
        default: () => {}
      }
    }
  }
}

// Component definition
class RadioGroup extends Component {
  constructor(props) {
    super(props, 'radio-group')
    props.form.set(props.id, null)
    props.form.add(props.id, 'required')
    props.form.require(props.id, props.requiredError)

    this.state = { value: null, answered: false, focused: false }
    if (props.default && props.default.value) {
      props.form.set(
        props.id,
        this.boolify(props.default ? props.default.value : null)
      )
      this.state = {
        value: this.boolify(props.default ? props.default.value : null)
      }
    }

    if (this.props.displayIfIdProvidedInUrl) {
      const paramValue =
        this.props.urlParams && this.props.urlParams[this.props.id]
      this.props.setVisibility(paramValue !== undefined)
    }
  }

  boolify(value) {
    if (!value) {
      return value
    }

    const nv = value.toLowerCase()

    if (nv === 'true') {
      return true
    } else if (nv === 'false') {
      return false
    }

    return value
  }

  componentDidMount() {
    let answered = false
    let paramValue =
      (this.props.urlParams && this.props.urlParams[this.props.id]) || ''
    // if (paramValue.length === 0) {
    //   // check if we have values for the group in quiz results
    //   const quizResults = this.props.cookies.get('quizResults')
    //   if (quizResults != null) {
    //     paramValue =
    //       quizResults.questionOneId === this.props.id
    //         ? quizResults.answerOne.answerValue
    //         : quizResults.questionTwoId === this.props.id
    //         ? quizResults.answerTwo.answerValue
    //         : ''
    //     answered = paramValue.length > 0
    //   }
    // }

    if (paramValue.length === 0) {
      const val = this.boolify(
        this.props.default ? this.props.default.value : ''
      )
      this.props.form.set(this.props.id, val)
      this.setState({ value: val })
    } else {
      answered = true
      const val = this.boolify(paramValue)
      this.props.form.set(this.props.id, val)
      this.setState({ value: val, answered })
    }

    if (this.props.hideIfAnswered && answered) {
      this.props.setVisibility(false)
    }
  }

  isChecked(valueA, valueB) {
    return valueA === valueB
  }

  handleRadioChange(e) {
    const value = this.boolify(e.currentTarget.value)
    this.setState({ value })
    this.props.form.set(this.props.id, value)
    this.props.onFormChange()
  }

  handleRadioFocus() {
    this.props.form.invalidate(this.props.id)
    this.setState({ focused: true })
  }

  handleRadioBlur() {
    this.props.form.validate(this.props.id)
    this.setState({ focused: false })
  }

  error() {
    return {
      __html:
        (this.props.form.errors &&
          this.props.form.errors.first(this.props.id)) ||
        '&nbsp;'
    }
  }

  render() {
    const error = this.error()
    const {
      layout,
      required,
      description,
      columns,
      list,
      alternativeContent,
      id
    } = this.props
    return (
      <div className={this.b()}>
        {description && layout === 'default' ? (
          <div className={this.e('description')}>
            <RichText markup={description} />
          </div>
        ) : null}
        <FormSet
          aria-label=""
          className={this.b()}
          columns={columns}
          id={id}
          required={required}
          items={list}
          description={layout === 'default' ? null : description}
          layout={layout}
          alternativeContent={alternativeContent}
          render={this.renderRadio}
          role="radiogroup"
        />
        {error && (!error.__html || error.__html !== '&nbsp;') && (
          <span className={this.e('error')} dangerouslySetInnerHTML={error} />
        )}
      </div>
    )
  }

  renderRadio(r, key) {
    if (this.state != null) {
      const paramValue =
        (this.props.urlParams && this.props.urlParams[this.props.id]) || ''
      r.checked = this.state.value === paramValue
    }

    const radio = new RadioButtonModel(r)
    const radioValue = this.boolify(radio.value)
    // console.log(`${this.props.id} = ${radio.value} vs ${this.state.value}, is checked ${this.isChecked(radio.value, this.state.value)}`)
    return (
      <RadioButton
        aria-label={this.props.id}
        key={key}
        alternativeContent={r.alternativeContent}
        fieldName={this.props.id}
        value={radioValue}
        checked={this.isChecked(radioValue, this.state.value)}
        label={radio.label}
        entryId={radio.entryId}
        name={radio.name}
        onChange={this.handleRadioChange}
        onBlur={this.handleRadioBlur}
        onFocus={this.handleRadioFocus}
      />
    )
  }
}

RadioGroup.props(RadioGroupModel)

const mapStateToProps = state => {
  return {
    urlParams: state.self.query
  }
}

export default connect(mapStateToProps)(withCookies(RadioGroup))
