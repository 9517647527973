import React, { useId } from 'react'

import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Card from 'app/components/Card/Card'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import { IconModel } from 'app/components/Icon/Icon'
import RichText from 'app/components/RichText/RichText'
import PhoneNumberModel from 'app/models/PhoneNumber/PhoneNumber'

import './ContactCard.scss'

// Module model definition
export class ContactCardModel extends Model {
  static props() {
    return {
      name: String,
      icon: IconModel.shape,
      parentName: { type: String, default: 'basic-contact' },
      title: { type: String, required: true },
      description: { type: String, required: true },
      links: PropTypes.arrayOf([LinkModel.shape, PhoneNumberModel.shape])
    }
  }
}

function renderButtons(b, props) {
  const { links, name, parentName } = props
  return (
    links &&
    links.map(l => {
      const link = new LinkModel(l)

      const button = {
        ...link.asButton,
        analyticsEvent: link.determineAnalyticsEvent(),
        analyticsValue: parentName
          ? `${slug(parentName)}|${slug(name)}|${slug(link.text)}`
          : `${slug(name)}|${slug(link.text)}`
      }

      if (link.type === 'phone') {
        button.design = 'link'
      }

      return (
        <div className={b.e('button').classes()} key={useId()}>
          <Button {...button} />
        </div>
      )
    })
  )
}

// Module definition
export default function ContactCard(props) {
  const b = Bem('contact-card')

  return (
    <Card
      className={b.and(props.className).classes()}
      icon={props.icon}
      title={props.title}
    >
      <div className={b.e('title').classes()}>{props.title}</div>

      <div className={b.e('description').classes()}>
        <RichText markup={props.description} />
      </div>

      <div className={b.e('buttons').classes()}>{renderButtons(b, props)}</div>
    </Card>
  )
}

ContactCard.propTypes = ContactCardModel.propTypes()
ContactCard.defaultProps = ContactCardModel.defaultProps()
