import React, { useEffect, useState } from 'react'

import { format } from 'date-fns'

import Bem from 'app/utils/bem-helper'
import Icon from 'app/components/Icon/Icon'
import { useChat } from 'app/modules/ChatContext'
import RichText from 'app/components/RichText/RichText'

import './ChatButton.scss'

const getChatButtonId = s => {
  var m = new RegExp(/#chat:([^:]+):([^:]+)(:([^:]+))?$/).exec(s)
  return m != null
    ? {
        buttonId: m[2],
        linkInstanceId: m[1],
        hideOnOnline: m[4] === 'offline',
        hideOnOffline: m[4] === 'online'
      }
    : null
}

const formatTime = hour => {
  const date = format(new Date(), 'yyyy-MM-dd')
  const custDate = new Date(`${date} ${hour}`)
  return format(custDate, 'h:mm aaa')
}

export default function ChatButton({ icon, link, className, analyticsValue }) {
  const [init, setInit] = useState(false)
  const [available, setAvailable] = useState(false)
  const [label, setLabel] = useState('Chat with us')
  const [klass, setKlass] = useState('chat-unavailable')
  const { liveagent, chatAvailable } = useChat()
  const b = Bem('chat-button')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const CHAT_INIT_URL = window.chatConfig.CHAT_INIT_URL
      const CHAT_INIT_DEPLOYMENT = window.chatConfig.CHAT_INIT_DEPLOYMENT
      const CHAT_INIT_ORG = window.chatConfig.CHAT_INIT_ORG

      if (
        window.liveagent &&
        CHAT_INIT_URL &&
        CHAT_INIT_DEPLOYMENT &&
        CHAT_INIT_ORG
      ) {
        setInit(true)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const CHAT_BUSINESS_HOURS_START =
        window.chatConfig.CHAT_BUSINESS_HOURS_START ||
        process.env.CHAT_BUSINESS_HOURS_START
      const CHAT_BUSINESS_HOURS_END =
        window.chatConfig.CHAT_BUSINESS_HOURS_END ||
        process.env.CHAT_BUSINESS_HOURS_END

      if (chatAvailable) {
        setLabel('Chat with us')
        setKlass('chat-available')
        setAvailable(true)
      } else {
        setLabel(
          `Chat with us <em className="offline">Available from <br />${formatTime(
            CHAT_BUSINESS_HOURS_START
          )} - ${formatTime(CHAT_BUSINESS_HOURS_END)} PT</em>`
        )
      }
    }
  }, [init, chatAvailable])

  const handleChat = async e => {
    e.preventDefault()
    if (available) {
      const href = link
      try {
        const b = await getChatButtonId(href)
        await liveagent?.startChat(b?.buttonId)
      } catch (error) {
        console.log(
          '------- chat start error --------->',
          '\n',
          error,
          '\n',
          getChatButtonId(href)
        )
      }
    }
  }

  const svg = (icon && icon.svg) || null
  // althouhgh this is not the best accessibilty practice, the chat needs an ahcnhor tag here unfortunately...
  return (
    <a
      href={link}
      className={b
        .m({ [klass]: true, 'has-icon': !!icon })
        .and(className)
        .classes()}
      onClick={handleChat}
      data-event="chatAction"
      data-value={analyticsValue}
    >
      {icon && (
        <Icon className={b.e('icon').classes()} type={icon.type} svg={svg} />
      )}
      <RichText
        className={b.e('text').classes()}
        markup={label}
        renderers={{ root: 'span' }}
      />
    </a>
  )
}
