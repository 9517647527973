import React, { Fragment } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MaskedInput from 'react-text-mask'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import { setFormFieldValues } from 'app/actions/form'

import { masks } from '../../modules/leadForm/ContactForm/logic'

import './FormField.scss'

export class FormFieldModel extends Model {
  static props() {
    return {
      id: PropTypes.string,
      placeholder: PropTypes.string,
      inputPlaceholder: PropTypes.string,
      area: PropTypes.bool,
      fieldType: PropTypes.string,
      fullWidth: PropTypes.bool,
      required: PropTypes.bool,
      requiredError: PropTypes.string,
      validation: PropTypes.string,
      validationError: PropTypes.string,
      defaultValue: PropTypes.string,
      maxLength: PropTypes.number,
      tooltip: PropTypes.string,
      disableAutocomplete: PropTypes.bool,
      form: PropTypes.shape(),
      onFormChange: PropTypes.func
    }
  }
  static defaultProps() {
    return {
      onFormChange: () => {},
      fullWidth: false,
      inputPlaceholder: ''
    }
  }
}

// Component definition
class FormField extends Component {
  constructor(props) {
    super(props, 'form-field')

    if (this.props.required) {
      this.props.form.require(this.props.id)
    }

    if (this.props.requiredError) {
      this.props.form.messages.add(
        this.props.id,
        'required',
        this.props.requiredError
      )
    }

    if (this.props.validation) {
      this.props.form.add(
        this.props.id,
        `regex:/${this.props.validation}/`,
        this.props.validationError
      )
    }

    if (this.props.validationError) {
      this.props.form.messages.add(
        this.props.id,
        'regex',
        this.props.validationError
      )
    }

    this.formName = 'form'

    this.state = {
      focused: false,
      value: this.props.form.reset(this.props.id, this.props.defaultValue)
    }

    this.props.setFormFieldValues(
      this.formName,
      this.props.id,
      this.props.defaultValue
    )
  }

  componentDidUpdate() {
    if (this.props.form.values[this.props.id] !== this.state.value) {
      this.setState({ value: this.props.form.values[this.props.id] })
      this.props.form.validate(this.props.id)
    }
  }
  getFieldValue() {
    // return this.state.value
    return this.props.forms[this.formName]
      ? this.props.forms[this.formName][this.props.id]
      : ''
  }

  get label() {
    return this.props.placeholder
  }

  error() {
    return {
      __html: this.props.form.errors.first(this.props.id) || '&nbsp;'
    }
  }

  onFocus() {
    this.props.form.invalidate(this.props.id)
    this.setState({ focused: true })
  }

  onBlur() {
    this.props.form.validate(this.props.id)
    this.setState({ focused: false })
    this.props.onFormChange(this.props.id, this.state.value, true)
  }

  onChange(evnt) {
    this.props.setFormFieldValues(
      this.formName,
      this.props.id,
      evnt.target.value
    )

    this.setState({
      value: this.props.form.set(this.props.id, evnt.target.value)
    })
    this.props.onFormChange(this.props.id, evnt.target.value)
  }

  render() {
    const props = {
      id: this.props.id,
      value: this.getFieldValue(),
      label: this.label,
      error: this.error(),
      mask: this.props.mask
    }

    return this.props.area
      ? this.renderTextArea(props)
      : this.renderInput(props)
  }

  renderTextArea(data) {
    return (
      <label
        htmlFor={data.id}
        className={this.e('input-label', {
          'full-width': this.props.fullWidth,
          focus: this.state.focused,
          filled: !!this.state.value,
          active: this.state.focused || !this.props.form.empty(this.props.id),
          textarea: this.props.area
        })}
      >
        <textarea
          id={data.id}
          rows="5"
          cols="50"
          maxLength={this.props.maxLength || 1500}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          value={data.value}
          aria-label={data.label}
          placeholder={data.label}
        />
        {data.error &&
          (!data.error.__html || data.error.__html !== '&nbsp;') && (
            <span
              className={this.e('error')}
              dangerouslySetInnerHTML={data.error}
            />
          )}
      </label>
    )
  }

  renderInput(data) {
    const isHidden = this.props.fieldType === 'hidden'
    if (isHidden) {
      return (
        <input type={this.props.fieldType} id={data.id} value={data.value} />
      )
    }

    const autoComplete = this.props.disableAutocomplete ? 'none' : 'on'

    return (
      <Fragment>
        <label
          htmlFor={data.id}
          className={this.e('input-label', {
            'full-width': this.props.fullWidth,
            focus: this.state.focused,
            filled: !!this.state.value,
            active: this.state.focused || !this.props.form.empty(this.props.id)
          })}
        >
          {data.mask && !this.props.browser.android ? (
            <MaskedInput
              className={this.e('masked-input', {
                'without-value': !data.value
              })}
              type="text"
              id={data.id}
              mask={masks[data.mask]}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              value={data.value}
              autoComplete={autoComplete}
            />
          ) : (
            <input
              type={this.props.fieldType}
              id={data.id}
              maxLength={this.props.maxLength || 50}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              value={data.value}
              autoComplete={autoComplete}
              placeholder={this.props.inputPlaceholder || null}
            />
          )}

          <span className={this.e('span')}>{data.label}</span>

          {this.props.tooltip && this.state.focused && (
            <span className={this.e('tooltip')}>{this.props.tooltip}</span>
          )}

          {data.error &&
            (!data.error.__html || data.error.__html !== '&nbsp;') && (
              <span
                className={this.e('error')}
                dangerouslySetInnerHTML={data.error}
              />
            )}
        </label>
      </Fragment>
    )
  }
}

FormField.propTypes = FormFieldModel.props()
FormField.defaultProps = FormFieldModel.defaultProps()

export default connect(
  state => ({
    browser: state.self.browser,
    forms: state.forms
  }),
  { setFormFieldValues }
)(FormField)
