import React from 'react'

import Bem from 'app/utils/bem-helper'
import Component from 'app/components/Component'

import './Sticky.scss'

export default class Sticky extends Component {
  constructor(props) {
    super(props, 'sticky')
    this.state = {
      fixed: false,
      posY: 0
    }
    this.onScroll = this.onScroll.bind(this)
    this.sticky = React.createRef()
  }

  componentDidMount() {
    this.setState({
      posY: this.findPosY(this.sticky)
    })
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  findPosY(node) {
    let curtop = 0
    if (node.offsetParent) {
      do {
        curtop += node.offsetTop
        node = node.offsetParent
      } while (node !== null)
    }
    return curtop
  }

  onScroll() {
    const fixed = this.state.posY > 0 && this.state.posY <= window.pageYOffset
    this.setState({ fixed })
  }

  render() {
    const b = Bem('sticky')
    const fixedClass = this.state.fixed ? ' fixed' : ''
    return (
      <div
        {...this.props}
        className={b
          .and(`${this.props.className || ''}${fixedClass}`)
          .classes()}
        ref={this.sticky}
      >
        {this.props.children}
      </div>
    )
  }
}
