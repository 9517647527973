import React from 'react'

const SvgMapPin = ({ color = '#33444E', ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 14 16" {...props}>
    <path
      d="M1 6.721c0 1.548.645 2.953 1.693 3.983.039.038.079.076.12.113L6.997 15l4.095-4.097c.103-.091.203-.186.299-.285C12.389 9.597 13 8.228 13 6.721 13 3.561 10.314 1 7 1S1 3.561 1 6.721z"
      stroke={color}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgMapPin
