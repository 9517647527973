import React, { useId } from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import MasterBox, {
  MasterBoxModel
} from 'app/modules/shared/MasterBox/MasterBox'

import './BasicTextBoxStack.scss'

// Component model definition
export class BasicTextBoxStackModel extends Model {
  static props() {
    return {
      orientation: {
        type: String,
        required: true
      },
      showDividers: { type: Boolean },
      textBoxes: {
        type: MasterBoxModel.arrayOf,
        required: true
      }
    }
  }
}

// Component definition
export default function BasicTextBoxStack(
  props = BasicTextBoxStackModel.defaultProps()
) {
  const { orientation, textBoxes, showDividers, button, button2 } = props

  const b = Bem('basic-text-box-stack')
  const rowStyle = orientation === 'row'
  const gridStyle = textBoxes.length === 4
  const shouldShowDividers = showDividers && rowStyle && textBoxes.length < 4

  return (
    <div className={b.classes()}>
      <div
        className={b
          .e('content')
          .m({ row: rowStyle, dividers: shouldShowDividers, grid: gridStyle })
          .classes()}
      >
        {textBoxes.map(box => {
          return <MasterBox key={useId()} {...box} />
        })}

        <div className={b.e('buttons').classes()}>
          {button}
          {button2}
        </div>
      </div>
    </div>
  )
}

BasicTextBoxStack.propTypes = BasicTextBoxStackModel.propTypes()
