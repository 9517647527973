import React, { Fragment } from 'react'

import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import FormSet from 'app/components/FormSet/FormSet'
import Checkbox, { CheckboxModel } from 'app/components/Checkbox/Checkbox'

import './CheckboxGroup.scss'
// Component model definition
export class CheckboxGroupModel extends Model {
  static props() {
    return {
      id: String,
      individual: Boolean,
      description: String,
      checkboxes: CheckboxModel.arrayOf,
      columns: Number,
      minimum: Number,
      minimumError: String,
      maximum: Number,
      maximumError: String,
      displayIfIdProvidedInUrl: Boolean,
      form: Object,
      onFormChange: {
        type: Function,
        default: () => {}
      }
    }
  }
}

// Component definition
export class CheckboxGroup extends Component {
  constructor(props) {
    super(props, 'checkbox-group')

    let paramValue =
      (this.props.urlParams && this.props.urlParams[this.props.id]) || ''
    if (paramValue.length === 0) {
      // check if we have values for the group in quiz results
      const quizResults = this.props.cookies.get('quizResults')
      if (quizResults != null) {
        paramValue =
          quizResults.questionOneId === this.props.id
            ? quizResults.answerOne.answerValue
            : quizResults.questionTwoId === this.props.id
              ? quizResults.answerTwo.answerValue
              : ''
      }
    }

    const paramValues = paramValue.split('|')

    const values = this.props.checkboxes.reduce((vals, checkbox) => {
      vals[checkbox.id] =
        paramValues.indexOf(checkbox.id) !== -1 || checkbox.initial || false
      if (props.individual) {
        this.props.form.set(checkbox.id, vals[checkbox.id])
      }
      return vals
    }, {})

    this.state = {
      values,
      errors: null
    }

    if (!props.individual) {
      this.props.form.set(this.props.id, this.values())
    }

    if (this.props.displayIfIdProvidedInUrl) {
      const paramValue =
        this.props.urlParams && this.props.urlParams[this.props.id]
      this.props.setVisibility(paramValue !== undefined)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.urlParams !== this.props.urlParams) {
      let paramValue =
        (this.props.urlParams && this.props.urlParams[this.props.id]) || ''
      // if (paramValue.length === 0) {
      //   // check if we have values for the group in quiz results
      //   const quizResults = this.props.cookies.get('quizResults')
      //   if (quizResults != null) {
      //     paramValue =
      //       quizResults.questionOneId === this.props.id
      //         ? quizResults.answerOne.answerValue
      //         : quizResults.questionTwoId === this.props.id
      //         ? quizResults.answerTwo.answerValue
      //         : ''
      //   }
      // }

      const paramValues = paramValue.split('|')

      const values = this.props.checkboxes.reduce((vals, checkbox) => {
        vals[checkbox.id] =
          paramValues.indexOf(checkbox.id) !== -1 || checkbox.initial || false
        if (this.props.individual) {
          this.props.form.set(checkbox.id, vals[checkbox.id])
        }
        return vals
      }, {})

      this.setState({ values })

      if (!this.props.individual) {
        this.props.form.set(this.props.id, this.values())
      }

      if (this.props.displayIfIdProvidedInUrl) {
        const paramValue =
          this.props.urlParams && this.props.urlParams[this.props.id]
        this.props.setVisibility(paramValue !== undefined)
      }
    }
  }

  m = () => {
    return {
      individual: this.props.individual
    }
  }

  values = () => {
    if (!this.props.individual) {
      return Object.keys(this.state.values).filter(key => {
        return this.state.values[key]
      })
    }

    return null
  }

  triggerError = (name, val) => {
    const { minimum, minimumError, maximum, maximumError } = this.props
    let errors = null
    const arrValues = Object.values({
      ...this.state.values,
      [name]: val
    }).filter(i => i === true)

    if (arrValues.length < minimum) {
      errors = minimumError
    }
    if (arrValues.length > maximum) {
      errors = maximumError
    }

    this.setState({ errors })
  }

  handleCheckboxChange = (name, val) => {
    this.triggerError(name, val)
    this.setState({
      values: {
        ...this.state.values,
        [name]: val
      }
    })

    setTimeout(() => {
      if (!this.props.individual) {
        this.props.form.set(this.props.id, this.values())
      } else {
        this.props.form.set(name, this.state.values[name])
      }
      if (this.state.errors) {
        this.props.form.invalidate(this.props.id)
      } else {
        this.props.form.validate(this.props.id)
      }
      this.props.onFormChange()
    }, 10)
  }

  renderCheckbox = (checkbox, key) => {
    if (this.state != null) {
      if (!this.props.individual) {
        if (this.state.values[checkbox.id] === true) {
          checkbox.initial = true
        }
      } else {
        if (this.state.values[checkbox.id] === true) {
          checkbox.initial = true
        }
      }
    }
    return (
      <Checkbox
        {...checkbox}
        name={this.props.individual ? checkbox.id : this.props.id}
        className={this.e('checkbox')}
        form={this.props.form}
        onChange={this.handleCheckboxChange}
        onBlur={this.triggerError}
        aria-label={this.props.id}
        // skipInitializeValue
        key={key}
      />
    )
  }

  render() {
    const { errors } = this.state
    return (
      <Fragment>
        <FormSet
          className={this.b()}
          columns={this.props.columns}
          id={this.props.id}
          items={this.props.checkboxes}
          render={this.renderCheckbox}
          description={this.props.description}
          role="group"
        />
        {errors && <span className={this.e('error')}>{errors}</span>}
      </Fragment>
    )
  }
}

CheckboxGroup.props(CheckboxGroupModel)

const mapStateToProps = state => {
  return {
    urlParams: state.self.query
  }
}

export default connect(mapStateToProps)(withCookies(CheckboxGroup))
