import React from 'react'

import PropTypes from 'prop-types'

import omit from 'lodash/omit'
import Bem from 'app/utils/bem-helper'

import './Chevron.scss'

// Component model definition
export class ChevronModel {
  static propTypes() {
    return {
      small: PropTypes.bool,
      thin: PropTypes.bool,
      white: PropTypes.bool,
      blue: PropTypes.bool,
      fast: PropTypes.bool,
      open: PropTypes.bool
    }
  }

  static defaultProps() {
    return {
      small: false,
      thin: false,
      white: false,
      blue: false,
      fast: false,
      open: false
    }
  }
}

// Component definition
export default function Chevron(props = ChevronModel.defaultProps()) {
  const b = Bem('chevron')
  const modifiers = {
    small: props.small,
    thin: props.thin,
    whiteChevron: props.white,
    blueChevron: props.blue,
    fast: props.fast,
    open: props.open
  }
  const mainProps = omit(props, [
    'small',
    'thin',
    'white',
    'blue',
    'fast',
    'open'
  ])
  return (
    <div
      {...mainProps}
      className={b.m(modifiers).and(props.className).classes()}
    />
  )
}

Chevron.propTypes = ChevronModel.propTypes()

