import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'

import './Hero.scss'
export class HeroModel extends Model {
  constructor(d) {
    super(d)
    this.cta = d.cta ? new LinkModel(d.cta) : undefined
  }

  static props() {
    return {
      name: {
        type: String
      },
      title: {
        type: String,
        default:
          'This is the title of the hero that in some cases could be long'
      },
      eyebrow: {
        type: String,
        default: ''
      },
      cta: LinkModel.shape,
      ctaAlt: LinkModel.shape,
      subtitle: String,
      description: String,
      heroClassName: String,
      theme: String,
      variant: String,
      backgroundSize: String,
      width: String
    }
  }
}

// Component definition
export default class Hero extends Component {
  constructor(props) {
    super(props, 'hero')
  }

  render() {
    const { eyebrow, title, subtitle, cta, breadcrumbs } = this.props

    return (
      <div className={this.b(this.props.heroClassName)}>
        {breadcrumbs}
        <div className={this.e('hero-body')}>
          <div className={this.e('aligned')}>
            {eyebrow && (
              <RichText
                renderers={{ root: 'span' }}
                markup={this.props.eyebrow}
                className={this.e('eyebrow')}
              />
            )}
            {title && (
              <h1 className={this.e('heading')}>
                <RichText markup={this.props.title} />
              </h1>
            )}
            {subtitle && (
              <RichText
                isMarkdown
                className={this.e('subtitle')}
                markup={this.props.subtitle}
              />
            )}
            {cta && (
              <div className={this.e('button-wrap')}>
                <Button
                  {...cta.asButton}
                  design="scotch-primary"
                  variant="light"
                  analyticsEvent={cta.determineAnalyticsEvent()}
                  analyticsValue={`${slug(this.props.name)}|${slug(cta.text)}`}
                  className={this.e('button')}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Hero.props(HeroModel)
