import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { ReactHeight } from '@shaw/react-component-library'

import Bem from 'app/utils/bem-helper'

import './Collapsable.scss'
// Component definition
export default function Collapsable({
  refCallback,
  children,
  className,
  isCollapsed = true
}) {
  const b = Bem('collapsable')
  const [ssrDone, setSsrDone] = useState(false)
  const [height, setHeight] = useState(0)
  const [dirty, setDirty] = useState(true)
  const ref = useRef()

  useEffect(() => {
    if (global && global.addEventListener) {
      global.addEventListener('resize', handleResize)
    }
    setSsrDone(true)
    return () => {
      if (global && global.removeEventListener) {
        global.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  function handleResize() {
    setDirty(true)
  }

  function onHeightReady(height) {
    setHeight(height)
    setDirty(false)
    if (refCallback) refCallback(ref)
  }

  function getStyle() {
    return ssrDone
      ? {
          height: isCollapsed && `${height ? height : 0}px`
        }
      : { height: 'auto' }
  }

  return (
    <div className={b.and(className).classes()} ref={ref} style={getStyle()}>
      <ReactHeight dirty={dirty} onHeightReady={onHeightReady}>
        {children}
      </ReactHeight>
    </div>
  )
}

Collapsable.propTypes = {
  isCollapsed: PropTypes.bool,
  children: PropTypes.node,
  refCallback: PropTypes.func
}
