import React from 'react'

import PropTypes from 'prop-types'

import Bem from 'app/utils/bem-helper'
import Chevron from 'app/components/Chevron/Chevron'

import './CollapsableDrawer.scss'

// Component model definition
export class CollapsableDrawerModel {
  constructor(d) {
    this.title = d.title
  }

  static propTypes() {
    return {
      title: PropTypes.string.isRequired,
      analytics: PropTypes.string
    }
  }

  static defaultProps() {
    return {
      analytics: ''
    }
  }
}

// Component definition
export default class CollapsableDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.b = Bem('collapsable-drawer')
    this.toggleExpanded = this.toggleExpanded.bind(this)
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { children, title, className } = this.props
    const buttonClass = this.b.e('toggle').c()
    const buttonContentClass = this.b.e('toggle-content').c()
    const iconClass = this.b.e('toggle-icon').c()
    const expandedClass = this.b
      .m({ expanded: this.state.expanded })
      .and(className)
      .c()
    const contentClass = this.b.e('content').c()

    return (
      <div className={`${expandedClass}`}>
        <button
          className={buttonClass}
          onClick={this.toggleExpanded}
          aria-haspopup="true"
        >
          <div className={buttonContentClass}>
            <h3 aria-level="3">{title}</h3>
            <Chevron
              className={iconClass}
              open={this.state.expanded}
              small
              fast
              white
            />
          </div>
        </button>
        <div className={contentClass} aria-label="submenu">
          {children}
        </div>
      </div>
    )
  }
}

CollapsableDrawer.propTypes = CollapsableDrawerModel.propTypes()
CollapsableDrawer.defaultProps = CollapsableDrawerModel.defaultProps()
