export const provinces = [
  { value: 'ab', label: 'Alberta' },
  { value: 'bc', label: 'British Columbia' },
  { value: 'mb', label: 'Manitoba' },
  { value: 'nb', label: 'New Brunswick' },
  { value: 'nl', label: 'Newfoundland and Labrador' },
  { value: 'ns', label: 'Nova Scotia' },
  { value: 'nt', label: 'Northwest Territories' },
  { value: 'nu', label: 'Nunavut' },
  { value: 'on', label: 'Ontario' },
  { value: 'pe', label: 'Prince Edward Island' },
  { value: 'qc', label: 'Québec' },
  { value: 'sk', label: 'Saskatchewan' },
  { value: 'yt', label: 'Yukon' }
]

export const getValidProvinces = (
  provs = process.env.VALID_PROVINCES || 'ab,bc,sk,mb,on'
) => {
  const validList =
    (typeof window !== 'undefined' && window.VALID_PROVINCES) || provs // eslint-disable-line
  const validProvinces = provinces.reduce((accumulator, province) => {
    if (validList.includes(province.value)) {
      accumulator.push(province)
    }
    return accumulator
  }, [])
  return validProvinces
}

export const getLocationQuery = (cookies, setCookie) => {
  let location = null
  let query = null
  if (typeof window !== 'undefined') {
    query =
      typeof URLSearchParams !== 'undefined' &&
      new URLSearchParams(document.location.search).get('location')
    location = query && query.match(/ab|bc|mb|sk|on/g)
  }
  const shawCookie = cookies && cookies.get('localePreference')
  const cookie = cookies && cookies.get('queryLocation')
  const cookieOptions = { path: '/', maxAge: 2592000 }

  const setLocationCookie = value =>
    cookies.set('queryLocation', value, cookieOptions)

  if (location && location[0]) {
    setCookie && setLocationCookie(location[0])
    return location[0]
  } else if (cookie) {
    return cookie
  } else if (shawCookie) {
    setCookie && setLocationCookie(shawCookie.Province.toLowerCase())
    return shawCookie.Province.toLowerCase()
  } else {
    return null
  }
}
