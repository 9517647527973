import React from 'react'

import SVG from 'react-inlinesvg'

import Model from 'app/models/Model'
import { icons } from 'app/utils/constants'
import ImageModel from 'app/models/Image/Image'
import Component from 'app/components/Component'
import CustomPropTypes from 'app/utils/validators'

import './Icon.scss'
// Component model definition
export class IconModel extends Model {
  constructor(props) {
    super(props)
    this.svg = props && props.svg ? new ImageModel(props.svg) : undefined
  }

  static props() {
    return {
      rotation: Number,
      type: {
        type: CustomPropTypes.icon,
        default: icons.close
      },
      svg: ImageModel.shape,
      height: { type: Number }
    }
  }
}

// Component definition
export default class Icon extends Component {
  constructor(props) {
    super(props, 'icon')
    this.bem.modifierDelimiter = '-'
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.type !== this.props.type
  }

  m() {
    return {
      [this.props.type]: true,
      [`rotate-${this.props.rotation}`]: !isNaN(this.props.rotation)
    }
  }

  render() {
    const dimensions = this.props.height ? { height: this.props.height } : {}
    if (this.props.svg && (this.props.svg.src || this.props.svg.file)) {
      return (
        <SVG
          cacheRequests={true}
          uniquifyIDs={true}
          src={this.props.svg.src || this.props.svg.file.url}
          className={this.b()}
          {...dimensions}
          preProcessor={code =>
            code.replace(
              'version="1.1"',
              'version="1.1" preserveAspectRatio="xMidYMax meet"'
            )
          }
        />
      )
    }

    return <span className={this.b()} aria-label={this.props.type} role="img" />
  }
}

Icon.props(IconModel)
