import React from 'react'

function SvgComponent({ color = '#0082bb', ...props }) {
  return (
    <svg width={452} height={258} viewBox="0 0 452 258" {...props}>
      <title>{'Caret'}</title>
      <path
        d="M225.923 257.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 54.157C-3.089 41.798-3.089 21.76 9.27 9.406c12.354-12.354 32.388-12.354 44.748 0l171.905 171.915L397.829 9.412c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 248.449c-6.177 6.172-14.274 9.257-22.369 9.257z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
