import React, { useId } from 'react'

import queryString from 'query-string'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import Icon from 'app/components/Icon/Icon'
import { icons } from 'app/utils/constants'

import './SearchPagination.scss'

// Component model definition
export class SearchPaginationModel extends Model {
  static props() {
    return {
      searchData: {
        type: Object,
        default: {
          pageCount: 0,
          currentPage: 0,
          keyword: ''
        }
      }
    }
  }
}

// Component definition
export default function SearchPagination(props) {
  const b = Bem('search-pagination')
  const { pageCount, currentPage, keyword } = props.searchData
  const { onPageClick } = props

  const paginationCases = [
    {
      condition: pageCount === 1,
      render: renderOnePage
    },
    {
      condition: pageCount > 1 && pageCount <= 5,
      render: renderFromOneToFive
    },
    {
      condition: pageCount > 5 && currentPage < pageCount - 1,
      render: renderMoreThanFiveRightEllipsis
    },
    {
      condition: pageCount > 5 && currentPage > pageCount - 1,
      render: renderMoreThanFiveLeftEllipsis
    }
  ]

  function onLinkClick(event, page) {
    if (typeof onPageClick === 'function') {
      event.preventDefault()
      onPageClick(page)
    }
  }

  function generatePageUrl(page) {
    const query = { page, q: keyword }
    return `?${queryString.stringify(query)}`
  }

  function createNumbersArray(numberOfItems) {
    return Array.from(Array(numberOfItems), (_, x) => x)
  }

  function createPageObject(pageNumber) {
    const { currentPage } = props.searchData
    return {
      number: pageNumber,
      href: generatePageUrl(pageNumber),
      current: pageNumber === currentPage
    }
  }

  function createPageLink({ href, number, current }) {
    return (
      <a
        className={b.e('link').m({ disabled: current }).c()}
        onClick={event => {
          onLinkClick(event, number)
        }}
        href={current ? null : href}
        data-event="navigationAction"
        data-value={`SearchResultsPagination|${number}`}
        key={useId()}
      >
        {number}
      </a>
    )
  }

  function createThreePageLinks(currentPage) {
    let seed

    if (currentPage === 1) {
      seed = [currentPage, currentPage + 1, currentPage + 2]
    } else if (currentPage === pageCount) {
      seed = [currentPage - 2, currentPage - 1, currentPage]
    } else {
      seed = [currentPage - 1, currentPage, currentPage + 1]
    }

    return seed.map(createPageObject).map(createPageLink)
  }

  function createEllipsis() {
    return <div className={b.e('ellipsis').c()}>...</div>
  }

  function createArrow(currentPage, right = false) {
    const page = right ? currentPage + 1 : currentPage - 1
    const orientation = right ? 'next' : 'previous'
    const disabled = page <= 0 || page > pageCount

    return (
      <a
        className={b.e('arrow').m({ right, disabled }).c()}
        href={disabled ? null : createPageObject(page).href}
        onClick={event => {
          onLinkClick(event, page)
        }}
        data-event="navigationAction"
        data-value={`SearchResultsPagination|${orientation}`}
      >
        <Icon type={icons.chevronBlue} />
      </a>
    )
  }

  function renderOnePage() {
    return (
      <div className={b.c()}>{createPageLink(createPageObject(pageCount))}</div>
    )
  }

  function renderFromOneToFive() {
    const pages = createNumbersArray(pageCount)
      .map(x => x + 1)
      .map(createPageObject)
    return (
      <div className={b.c()}>
        {createArrow(currentPage)}
        {pages.map(createPageLink.bind(this))}
        {createArrow(currentPage, true)}
      </div>
    )
  }

  function renderMoreThanFiveRightEllipsis() {
    return (
      <div className={b.c()}>
        {createArrow(currentPage)}
        {createThreePageLinks(currentPage)}
        {createEllipsis()}
        {createPageLink(createPageObject(pageCount))}
        {createArrow(currentPage, true)}
      </div>
    )
  }

  function renderMoreThanFiveLeftEllipsis() {
    return (
      <div className={b.c()}>
        {createArrow(currentPage)}
        {createPageLink(createPageObject(1))}
        {createEllipsis()}
        {createThreePageLinks(currentPage)}
        {createArrow(currentPage, true)}
      </div>
    )
  }

  for (const renderCase in paginationCases) {
    if (paginationCases[renderCase].condition) {
      return paginationCases[renderCase].render()
    }
  }

  return null
}

SearchPagination.propTypes = SearchPaginationModel.propTypes()
SearchPagination.defaultProps = SearchPaginationModel.defaultProps()
