import React, { Fragment } from 'react'

import axios from 'axios'
import Autocomplete from 'downshift'
import { connect } from 'react-redux'

import Model from 'app/models/Model'
import Icon from 'app/components/Icon/Icon'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import { canUseDOM, isLarge } from 'app/utils/env-helpers'

import './SearchBar.scss'
export class SearchBarModel extends Model {
  static props() {
    return {
      placeholder: {
        type: String,
        default: 'Search'
      },
      searchPage: {
        type: LinkModel.shape,
        default: LinkModel.defaultProps()
      },
      searchTerm: {
        type: String,
        default: ''
      },
      cssModification: String,
      onOpen: { type: Function, default: () => {} },
      onClose: { type: Function, default: () => {} }
    }
  }
}
class SearchBar extends Component {
  constructor(props) {
    super(props, 'search-bar')

    this.state = {
      opened: false,
      searchTerm: props.searchTerm || '',
      searchSuggestions: []
    }
    this.mounted = false

    this.closeInputContainer = this.closeInputContainer.bind(this)
    this.onPopState = this.onPopState.bind(this)

    this.autocompleteRef = React.createRef()
    this.formRef = React.createRef()
  }

  onPopState(event) {
    if (event && event.state) {
      const safeSearchTerm =
        event?.state?.keyword?.replace(/\s/g, '%20') || '%20'
      this.setState({ searchTerm: safeSearchTerm || '%20' })
    }
  }

  componentDidMount() {
    const body = document.querySelector('body')

    if (!this.state.searchTerm) {
      const keyword =
        this.props.request && this.props.request.query
          ? this.props.request.query.q
          : ''
      const safeSearchTerm = keyword && keyword.replace(/\s/g, '%20')
      this.setState({ searchTerm: safeSearchTerm || '%20' })
    }

    body.addEventListener('click', this.handleBodyClick)
    window.addEventListener('popstate', this.onPopState)
    this.mounted = true
  }

  componentWillUnmount() {
    const body = document.querySelector('body')
    body.removeEventListener('click', this.handleBodyClick)
    window.removeEventListener('popstate', this.onPopState)
    this.mounted = false
  }

  m() {
    return {
      opened: this.state.opened
    }
  }

  handleBodyClick(e) {
    if (!this.state.opened) {
      return
    }
    const xVal = e.clientX
    const yVal = e.clientY
    let searchBarRect = this.formRef.current.getBoundingClientRect()
    if (
      xVal < searchBarRect.x ||
      xVal > searchBarRect.x + searchBarRect.width ||
      yVal < searchBarRect.y ||
      yVal > searchBarRect.y + searchBarRect.height + 150
    ) {
      setTimeout(() => {
        this.closeInputContainer()
      })
    }
  }

  closeInputContainer() {
    this.setState({ opened: false })
    this.props.onClose()
  }

  openInputContainer() {
    this.setState({ opened: true })
    this.props.onOpen()
    setTimeout(() => {
      this.autocompleteRef.current && this.autocompleteRef.current.focus()
    })
  }

  buttonHandler(event) {
    event.preventDefault()
    event.stopPropagation()

    this.openInputContainer()
    this.autocompleteRef.current && this.autocompleteRef.current.focus()
  }

  closeButtonHandler(event) {
    event.preventDefault()
    event.stopPropagation()
    if (!this.largeScreen() && this.hasValue()) {
      this.setState({ searchTerm: '' })
    }
    this.closeInputContainer()
  }

  changeHandler({ target }) {
    const searchUrl = this.props.searchUrl || window.SEARCH_URL
    this.setState({ searchTerm: target.value?.replace(/\s/g, '%20') || '' })
    axios
      .post(searchUrl, {
        companyId: 211,
        language: 'en',
        query: target.value
      })
      .then(({ data }) => {
        this.setState({ searchSuggestions: data })
      })
  }

  formSubmitHandler(event) {
    const searchLink = new LinkModel(this.props.searchPage)
    const safeSearchTerm =
      this.state.searchTerm && this.state.searchTerm.replace(/\s/g, '%20')
    if (!this.hasValue()) {
      event.preventDefault()
    } else if (typeof this.props.onSearch === 'function') {
      event.preventDefault()
      this.props.onSearch(safeSearchTerm)
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = `${searchLink.url}?q=${safeSearchTerm}`
      }
    }
  }

  selectHandler(value) {
    const { searchTerm } = this.state
    if (value && value !== searchTerm) {
      this.setState({ searchTerm: value })
      setTimeout(() => {
        this.formSubmitHandler()
      }, 100)
    }
  }

  magnifyGlassHasValue() {
    return this.hasValue() && !this.largeScreen() && this.state.opened
  }

  hasValue() {
    return this.state.searchTerm && this.state.searchTerm.length > 0
  }

  transitionEndHandler() {
    if (this.mounted && !this.state.opened) {
      this.setState({ searchTerm: '' })
    }
  }

  largeScreen() {
    return canUseDOM() && isLarge()
  }

  render() {
    const { searchPage, placeholder } = this.props
    const { searchTerm, searchSuggestions } = this.state
    const searchLink = new LinkModel(searchPage)

    return (
      <div
        className={this.b(this.props.cssModification)}
        onTransitionEnd={this.transitionEndHandler}
      >
        <button
          className={this.e('button', {
            'has-value': this.magnifyGlassHasValue()
          })}
          onClick={this.buttonHandler}
          data-event="searchAction"
          data-value="global-nav|search"
        >
          <Icon className={this.e('icon')} type="Nav_Search" />
          <span className={this.e('label')}>Search</span>
        </button>
        <form
          key={'searchbarform'}
          action={searchLink.url}
          autoComplete="off"
          className={this.e('input-container')}
          ref={this.formRef}
          onSubmit={this.formSubmitHandler}
        >
          <fieldset disabled={!this.state.opened}>
            <div className={this.e('input-wrapper')}>
              <Autocomplete
                onChange={selection => this.selectHandler(selection)}
                itemToString={item => (item ? item : '')}
                labelId="downshift-label"
                menuId="downshift-search-id"
              >
                {({
                  getInputProps,
                  getItemProps,
                  getMenuProps,
                  isOpen,
                  inputValue,
                  highlightedIndex,
                  selectedItem,
                  getRootProps
                }) => (
                  <Fragment>
                    <div
                      {...getRootProps({}, { suppressRefError: true })}
                      className={this.e('autocomplete')}
                    >
                      <Icon className={this.e('icon')} type="Nav_Search" />
                      <input
                        {...getInputProps({
                          onChange: this.changeHandler
                        })}
                        id="search-bar-input-autocomplete"
                        ref={this.autocompleteRef}
                        className={this.e('input')}
                        name="q"
                        aria-label="search-bar-input"
                        aria-labelledby={null}
                      />
                    </div>
                    <ul
                      className={this.e('search-results')}
                      aria-label="search results"
                      {...getMenuProps()}
                    >
                      {isOpen
                        ? searchSuggestions
                            .filter(
                              item =>
                                !inputValue ||
                                item
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase())
                            )
                            .map((item, index) => (
                              <li
                                {...getItemProps({
                                  key: item,
                                  index,
                                  item,
                                  style: {
                                    backgroundColor:
                                      highlightedIndex === index
                                        ? 'lightgray'
                                        : 'white',
                                    fontWeight:
                                      selectedItem === item ? 'bold' : 'normal'
                                  }
                                })}
                                key={`${item}-${index}`}
                                className={this.e('search-results-item')}
                              >
                                {item}
                              </li>
                            ))
                        : null}
                    </ul>
                  </Fragment>
                )}
              </Autocomplete>
              <button
                type="reset"
                aria-label="Close Search"
                className={this.e('close-button')}
                onClick={this.closeButtonHandler}
              />
            </div>
          </fieldset>
        </form>
      </div>
    )
  }
}

SearchBar.props(SearchBarModel)

export default connect(state => {
  return {
    request: state.self,
    searchData: state.search
  }
})(SearchBar)
