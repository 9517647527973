import React from 'react'

import PropTypes from 'prop-types'

import Component from 'app/components/Component'

// Component definition
export default class Transition extends Component {
  constructor(props) {
    super(props, props.name)

    this.state = {
      timer: null,
      direction: 'leave'
    }

    this.callbacks = {
      onBegin: this.props.onBegin,
      onComplete: this.props.onComplete,
      enter: {
        onBegin: this.props.onEnterBegin,
        onComplete: this.props.onEnterComplete
      },
      leave: {
        onBegin: this.props.onLeaveBegin,
        onComplete: this.props.onLeaveComplete
      }
    }
  }

  componentWillUnmount() {
    this.resetTimer()
  }

  resetTimer(direction) {
    if (this.state.timer) {
      clearTimeout(this.state.timer)
      this.setState({ timer: null })
    }

    if (direction) {
      const time = this.props[direction]
      const timer = setTimeout(this.complete, time)

      this.setState({
        timer,
        direction
      })
    }
  }

  complete() {
    this.resetTimer()

    this.callbacks[this.state.direction].onComplete()

    this.callbacks.onComplete()
  }

  transition(direction) {
    if (this.state.direction === direction) {
      return
    }

    this.resetTimer(direction)

    this.callbacks[direction].onBegin()

    this.callbacks.onBegin()
  }

  enter() {
    this.transition('enter')
  }

  leave() {
    this.transition('leave')
  }

  toggle() {
    const direction = this.state.direction === 'enter' ? 'leave' : 'enter'

    this.transition(direction)
  }

  m() {
    return {
      [this.state.direction]: true,
      [this.state.direction + '-active']: !!this.state.timer
    }
  }

  render() {
    const props = {
      id: this.props.id,
      className: this.b()
    }

    return React.createElement(this.props.tag, props, this.props.children)
  }
}

Transition.props({
  tag: {
    type: PropTypes.node,
    default: 'span'
  },
  name: {
    type: String,
    default: 'transition'
  },
  enter: {
    type: Number,
    default: 0
  },
  leave: {
    type: Number,
    default: 0
  },
  onBegin: Function,
  onComplete: Function,
  onEnterBegin: Function,
  onEnterComplete: Function,
  onLeaveBegin: Function,
  onLeaveComplete: Function
})
