import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import Bem from 'app/utils/bem-helper'
import Icon from 'app/components/Icon/Icon'
import Link from 'app/components/Link/Link'
import RichText from 'app/components/RichText/RichText'

import './ProductSpecList.scss'

export default function ProductSpecList({ items = null }) {
  const b = Bem('product-spec-list')
  const specs = items.filter(item => item !== null)

  if (!specs.length) return null

  const renderItems = item => {
    return (
      <Fragment key={item.unit}>
        {item.icon && item.icon.type && (
          <div className={b.e('icon').classes()}>
            <Icon {...item.icon} />
          </div>
        )}
        {item.number && (
          <span className={b.e('item-number').classes()}>{item.number}</span>
        )}

        {item.unit && (
          <RichText
            isMarkdown
            className={b.e('item-unit').classes()}
            markup={item.unit}
          />
        )}

        {item.description && (
          <span className={b.e('item-description').classes()}>
            {item.description}
          </span>
        )}
      </Fragment>
    )
  }

  return (
    <div className={b.m({ four: specs.length === 4, scotch: true }).classes()}>
      {specs.map(item => {
        if (item && item.name) {
          return (
            <div key={item.entryId} className={b.e('item').classes()}>
              {item.link ? (
                <Link
                  className={b.e('item-link').classes()}
                  link={item.link}
                  useChildren
                >
                  {renderItems(item)}
                </Link>
              ) : (
                <div className={b.e('item-container').classes()}>
                  {renderItems(item)}
                </div>
              )}
            </div>
          )
        }
      })}
    </div>
  )
}


ProductSpecList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape())
}
