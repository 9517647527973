import React from 'react'

import chunk from 'lodash/chunk'
import Model from 'app/models/Model'
import Component from 'app/components/Component'
import RichText from 'app/components/RichText/RichText'

import './FormSet.scss'

// Component model definition
export class FormSetModel extends Model {
  static props() {
    return {
      items: Array,
      columns: Number,
      tight: Boolean,
      render: Function,
      description: String,
      layout: { type: String, default: 'default' }
    }
  }
}

// Component definition
export default class FormSet extends Component {
  constructor(props) {
    super(props, 'form-set')
  }

  m() {
    return {
      [this.props.layout]: true
    }
  }

  render() {
    this.index = 0
    return (
      <fieldset
        className={this.b()}
        role={this.props.role}
        id={this.props.id}
        aria-label={this.props.id}
      >
        {this.props.description && (
          <div className={this.e('description')}>
            <RichText markup={this.props.description} />
          </div>
        )}
        {chunk(this.props.items, this.props.columns || 4).map(this.renderRow)}
      </fieldset>
    )
  }

  renderRow(items, key) {
    while (items.length < this.props.columns) {
      items.push(undefined)
    }

    return (
      <div className={this.e('row', { tight: this.props.tight })} key={key}>
        {items.map(this.renderItem)}
      </div>
    )
  }

  renderItem(item) {
    const modifiers = {
      empty: !item,
      tight: this.props.tight
    }

    return (
      <div className={this.e('item', modifiers)} key={this.index++}>
        {item && this.props.render(item, this.index)}
      </div>
    )
  }
}

FormSet.props(FormSetModel)
