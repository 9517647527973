import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import { Hero } from '@shaw/react-component-library'
import { HeroModel } from 'app/components/Hero/Hero'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'

import './DefaultHero.scss'

// Component model definition
export class DefaultHeroModel extends Model {
  constructor(d) {
    super(d)
    this.cta = d.cta ? new LinkModel(d.cta) : undefined
    this.responsiveImage = d.responsiveImage || d.image
  }

  static props() {
    return {
      name: String,
      title: {
        type: String,
        required: true,
        default:
          'This is the title of the hero that in some cases could be long'
      },
      eyebrow: {
        type: String,
        required: true,
        default: 'This is the eyebrow'
      },
      cta: LinkModel.shape,
      subtitle: String,
      description: String,
      responsiveImage: {
        type: ResponsiveImageModel.shape,
        required: false
      },
      layout: {
        type: String,
        required: true,
        default: 'primary'
      },
      theme: {
        type: String,
        default: 'white'
      },
      variant: {
        type: String,
        default: 'withPattern'
      }
    }
  }
}

// Component definition

export default class DefaultHero extends Component {
  constructor(props) {
    super(props, 'default-hero')

    this.state = {
      page: ''
    }
  }

  componentDidMount() {
    const trackPage =
      typeof window !== 'undefined' && location.pathname !== '/'
        ? `${slug(location.pathname)}|`
        : 'homepage|'

    this.setState({
      page: trackPage
    })
  }

  render() {
    const heroProps = new HeroModel(this.props)
    const cta = this.props.cta ? new LinkModel(heroProps.cta) : null
    const responsiveImage = this.props.responsiveImage
      ? {
          large: this.props.responsiveImage.large
            ? this.props.responsiveImage.large?.file?.url
            : null,
          medium: this.props.responsiveImage.medium
            ? this.props.responsiveImage.medium?.file?.url
            : null,
          small: this.props.responsiveImage.small
            ? this.props.responsiveImage.small?.file?.url
            : null
        }
      : null

    return (
      <div className={this.b()}>
        <Hero
          {...heroProps}
          cta={
            cta
              ? {
                  type: 'button',
                  ...cta.asButton,
                  variant: cta.style,
                  'data-event': cta.determineAnalyticsEvent(),
                  'data-value': `content|${this.state.page}${slug(
                    heroProps.title || heroProps.name
                  )}|${slug(cta.text)}`
                }
              : null
          }
          responsiveImage={responsiveImage}
          heroClassName={this.props.heroClassName}
        />
      </div>
    )
  }
}

DefaultHero.props(DefaultHeroModel)
