import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Scroll from 'react-scroll'

import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Icon from 'app/components/Icon/Icon'
import { icons } from 'app/utils/constants'
import { createRegisterModuleAnchor } from 'app/actions/shared'

import './Button.scss'

// Component model definition
export class ButtonModel {
  constructor(d) {
    this.text = d.text
    this.type = d.type
    this.design = d.design
    this.variant = d.variant
    this.analyticsEvent = d.analyticsEvent
    this.analyticsValue = d.analyticsValue || slug(d.text)
    this.link = d.link
    this.icon = d.icon
  }

  static propTypes() {
    return {
      // All types
      text: PropTypes.string.isRequired,
      type: PropTypes.string,
      design: PropTypes.string,
      variant: PropTypes.string,
      analyticsEvent: PropTypes.string,
      analyticsValue: PropTypes.string,
      className: PropTypes.string,
      tabIndex: PropTypes.string,
      ariaLabel: PropTypes.string,
      // type=link only
      link: PropTypes.string,
      target: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      offsite: PropTypes.bool,
      withCaret: PropTypes.bool,
      // type=button only
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      // actions
      registerModuleAnchor: PropTypes.func,
      // anchor only
      anchor: PropTypes.shape({
        moduleTarget: PropTypes.shape({
          entryId: PropTypes.number
        })
      })
    }
  }

  static defaultProps() {
    return {
      text: 'Button label',
      type: 'button',
      design: 'scotch-primary',
      variant: 'light',
      analyticsEvent: 'navigationAction',
      analyticsValue: '',
      className: '',
      link: '#',
      target: '_self',
      icon: null,
      withCaret: false,
      onClick: () => {},
      disabled: false,
      tabIndex: '0',
      offsite: false,
      registerModuleAnchor: () => {}
    }
  }
}

// Component definition
export class Button extends React.Component {
  constructor(props) {
    super(props)
    this.b = Bem('button')
    this.handleClick = this.handleClick.bind(this)
    this.state = {
      url: ''
    }
  }

  componentDidMount() {
    this.registerModuleAnchor()
    if (typeof window !== 'undefined') {
      this.setState({ url: location.pathname })
    }
  }

  registerModuleAnchor() {
    if (this.props.anchor) {
      this.props.registerModuleAnchor(this.props.anchor)
    }
  }

  handleClick(e) {
    this.props.onClick(e)
  }

  renderAsAnchor(buttonClasses) {
    const { text, analyticsEvent, analyticsValue, tabIndex, ariaLabel } =
      this.props

    return (
      <Scroll.Link
        to={this.props.anchor.targetModule.entryId}
        smooth
        offset={-40}
        duration={300}
        isDynamic
        tabIndex={tabIndex}
        className={buttonClasses}
        data-event={analyticsEvent}
        data-value={analyticsValue}
        aria-label={ariaLabel}
        data-testid="button"
      >
        <span className={this.b.e('text').c()}>{text}</span>
      </Scroll.Link>
    )
  }

  renderLinkAsButton(buttonClasses) {
    const {
      link,
      target,
      text,
      analyticsEvent,
      analyticsValue,
      tabIndex,
      ariaLabel,
      icon
    } = this.props

    const svg = (icon && icon.svg) || null // eslint-disable-line
    return (
      <a
        href={link}
        tabIndex={tabIndex}
        className={`${buttonClasses} ${icon && 'has-icon'}`}
        target={target}
        rel={target === '_blank' ? 'noreferrer' : null}
        onClick={this.handleClick}
        data-event={analyticsEvent}
        data-value={analyticsValue}
        aria-label={ariaLabel}
        data-testid="button"
      >
        <span className={this.b.e('text').c()}>{text}</span>
        {icon && (
          <Icon className={this.b.e('icon')} type={icon.type} svg={svg} />
        )}
      </a>
    )
  }

  renderAsLink(buttonClasses) {
    const {
      link,
      target,
      icon,
      text,
      analyticsEvent,
      analyticsValue,
      tabIndex,
      offsite,
      ariaLabel,
      withCaret
    } = this.props

    let caret = null // <Icon type={icons.chevronWhite} className='button--icon' /> : null
    let offsiteIcon = null
    if (offsite) {
      offsiteIcon = <Icon type={icons.offsite} className="button--icon" />
      caret = null
    }
    const svg = (icon && icon.svg) || null // eslint-disable-line

    return (
      <a
        href={link}
        tabIndex={tabIndex}
        className={buttonClasses}
        target={target}
        rel={target === '_blank' ? 'noreferrer' : null}
        onClick={this.handleClick}
        data-event={analyticsEvent}
        data-value={analyticsValue}
        aria-label={ariaLabel}
        data-testid="button"
      >
        {offsiteIcon}
        {icon && !withCaret && (
          <Icon className={this.b.e('icon')} type={icon.type} svg={svg} />
        )}
        <span
          className={`${this.b.e('text').c()} ${
            icon ? `${this.b.e('text').c()}--is-bold` : ''
          }`}
        >
          {text}

          {icon && withCaret && (
            <Icon className={this.b.e('icon')} type={icon.type} svg={svg} />
          )}
        </span>
        {caret}
      </a>
    )
  }

  renderAsButton(buttonClasses) {
    const {
      type,
      disabled,
      onClick,
      text,
      analyticsEvent,
      analyticsValue,
      tabIndex,
      ariaLabel,
      ref,
      icon
    } = this.props
    const svg = (icon && icon.svg) || null

    return (
      <button
        ref={ref}
        className={buttonClasses}
        type={type}
        tabIndex={tabIndex}
        disabled={disabled}
        onClick={onClick}
        data-event={analyticsEvent}
        data-value={analyticsValue}
        data-testid="button"
        aria-label={ariaLabel}
      >
        {icon && (
          <Icon className={this.b.e('icon')} type={icon.type} svg={svg} />
        )}
        <span className={this.b.e('text').c()}>{text}</span>
      </button>
    )
  }

  renderBasedOnType(buttonClass) {
    const { type, design, anchor } = this.props

    if (anchor) {
      return this.renderAsAnchor(buttonClass)
    } else if (
      type === 'link' &&
      design !== 'link' &&
      design !== 'link-caret' &&
      design !== 'link-caret-contrast' &&
      design !== 'link-caret-white' &&
      design !== 'link-white'
    ) {
      return this.renderLinkAsButton(buttonClass)
    } else if (
      type === 'link' &&
      (design === 'link' || design === 'link-white')
    ) {
      return this.renderAsLink(buttonClass, false)
    } else if (
      type === 'link' &&
      (design === 'link-caret' ||
        design === 'link-caret-contrast' ||
        design === 'link-caret-white')
    ) {
      return this.renderAsLink(buttonClass, true)
    }

    return this.renderAsButton(buttonClass)
  }

  render() {
    const { url } = this.state
    const { design, variant, className, activeRoute } = this.props
    const activeRegexp = new RegExp(activeRoute)
    const route = url.split('/')
    const isActiveRoute =
      activeRoute && activeRegexp.test(`/${route[1]}`) === true
    const isActive = url === this.props.link || isActiveRoute
    const buttonClasses = this.b
      .m({
        'is-active': isActive,
        [design]: true,
        [variant]: true
      })
      .and(className)
      .classes()
    return this.renderBasedOnType(buttonClasses)
  }
}

Button.propTypes = ButtonModel.propTypes()
Button.defaultProps = ButtonModel.defaultProps()

const mapDispatchToProps = dispatch => ({
  registerModuleAnchor: anchor => {
    dispatch(createRegisterModuleAnchor(anchor))
  }
})

export default connect(null, mapDispatchToProps)(Button)
