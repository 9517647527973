import React from 'react'

import PropTypes from 'prop-types'

import Bem from 'app/utils/bem-helper'
import Icon, { IconModel } from 'app/components/Icon/Icon'

import './IconBadge.scss'

// Component model definition
export class IconBadgeModel {
  constructor(d) {
    this.icon = new IconModel(d)
    this.label = d.label
    this.customIcon = d.customIcon
  }

  static propTypes() {
    return {
      icon: IconModel.shape,
      label: PropTypes.string,
      customIcon: PropTypes.string
    }
  }

  static defaultProps() {
    return {}
  }
}

// Component definition
export default function IconBadge(props = IconBadgeModel.defaultProps()) {
  const b = Bem('icon-badge')
  return (
    <div className={b.and(props.className).classes()}>
      {props.customIcon ? (
        <img
          className={b.e('image').classes()}
          src={props.customIcon}
          aria-label={props.label}
        />
      ) : (
        <Icon {...props.icon} />
      )}
    </div>
  )
}

IconBadge.propTypes = IconBadgeModel.propTypes()

