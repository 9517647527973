import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import Icon, { IconModel } from 'app/components/Icon/Icon'
import { DisclaimerCta } from '@shaw/react-component-library'
import ProductAddOnModel from 'app/models/ProductAddOn/ProductAddOn'

import './ProductAddOnIconBox.scss'

// Component model definition
export class ProductAddOnIconBoxModel extends Model {
  constructor(d, parentName = false) {
    super(d)

    this.icon = new IconModel(d.icon)
    this.productAddOn = new ProductAddOnModel(d.productAddOn)
    this.cta =
      d.cta && d.cta.contentType === 'link' ? new LinkModel(d.cta) : undefined

    // Computed
    if (this.cta) {
      this.analyticsEvent =
        this.cta.analyticsEvent || this.cta.determineAnalyticsEvent()
      this.analyticsValue = parentName
        ? `${slug(parentName)}|${slug(d.name)}|${slug(this.cta.text)}`
        : `${slug(d.name)}|${slug(this.cta.text)}`
    }
  }

  static props() {
    return {
      icon: {
        type: IconModel.shape,
        required: true,
        default: IconModel.defaultProps()
      },
      productAddOn: {
        type: ProductAddOnModel.shape,
        required: true,
        default: ProductAddOnModel.defaultProps()
      },
      cta: LinkModel.shape,
      untabbable: { type: Boolean, default: false },
      superscript: String,
      analyticsEvent: String,
      analyticsValue: String
    }
  }
}

// Component definition
export default function ProductAddOnIconBox(props) {
  const b = Bem('product-add-on-icon-box')
  return (
    <div className={b.classes()}>
      <div className={b.e('icon').classes()}>
        <Icon {...props.icon} />
      </div>

      <div className={b.e('name').classes()}>
        {props.productAddOn.productName}
      </div>

      <div className={b.e('description').classes()}>
        <RichText markup={props.productAddOn.shortDescription} />
      </div>
      <div className={b.e('price-block').classes()}>
        {props.productAddOn.price && props.productAddOn.pricePrefix && (
          <span className={b.e('price-prefix').classes()}>
            {props.productAddOn.pricePrefix}
          </span>
        )}
        {props.productAddOn.price && (
          <p className={b.e('price').classes()}>
            <sup className={b.e('price-symbol').classes()}>$</sup>
            {props.productAddOn.price}
            <span className={b.e('per-text').classes()}>
              /mo
              <DisclaimerCta
                href={props.superscript}
                className={b.e('asterisk-symbol').classes()}
              >
                {props.superscript}
              </DisclaimerCta>
            </span>
          </p>
        )}
        {props.productAddOn.price && props.productAddOn.priceSuffix && (
          <span className={b.e('price-suffix').classes()}>
            {props.productAddOn.priceSuffix}
          </span>
        )}
      </div>

      {props.cta && (
        <div className={b.e('cta').classes()}>
          <Button
            {...props.cta.asButton}
            offsite={props.cta.offsite}
            design={props.cta.isPhone ? 'link' : 'link-caret'}
            analyticsEvent={props.analyticsEvent}
            analyticsValue={props.analyticsValue}
            tabIndex={props.untabbable ? '-1' : '0'}
          />
        </div>
      )}
    </div>
  )
}

ProductAddOnIconBox.propTypes = ProductAddOnIconBoxModel.propTypes()
ProductAddOnIconBox.defaultProps = ProductAddOnIconBoxModel.defaultProps()
