import React from 'react'

import chunk from 'lodash/chunk'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Component from 'app/components/Component'
import ItemsListBox, {
  ItemsListBoxModel
} from 'app/components/ItemsListBox/ItemsListBox'

import './ItemGroup.scss'
// Component model definition
export class ItemGroupModel extends Model {
  static props() {
    return {
      name: String,
      title: String,
      itemsListBox: PropTypes.arrayOf(ItemsListBoxModel.shape).isRequired
    }
  }
}

// Module definition
class ItemGroup extends Component {
  constructor(props) {
    super(props, 'item-group')
  }

  m() {
    return {
      scotch: true
    }
  }

  render() {
    return (
      <div className={this.b()}>
        <div className={this.e('container')}>
          <div className={this.e('title')}>
            <span>{this.props.title}</span>
          </div>
          {this.renderItemsListBoxGroups()}
        </div>
      </div>
    )
  }

  renderItemsListBoxGroups() {
    if (!this.props.itemsListBox) {
      return null
    }

    const chunks = chunk(this.props.itemsListBox, 2)

    const parentNode = slug(this.props.path)

    return chunks.map((chunk, cidx) => {
      const items = chunk.map(item => {
        return (
          <div
            key={uuid()}
            className={this.e(
              'items-column',
              chunk.length === 1 ? 'one' : 'two'
            )}
          >
            <ItemsListBox
              className={this.e('items-list-box')}
              {...item}
              parentNodeMame={parentNode}
            />
          </div>
        )
      })

      return (
        <div key={cidx} className={this.e('items-row')}>
          {items}
        </div>
      )
    })
  }
}

const mapStateToProps = state => ({
  path: state.self.path
})

ItemGroup.props(ItemGroupModel)

export default connect(mapStateToProps)(ItemGroup)
