import React from 'react'

import fromPairs from 'lodash/fromPairs'
import pick from 'lodash/pick'

import Bem from 'app/utils/bem-helper'
import { defaultProps, propTypes } from 'app/utils/prop-types'

// TODO: implement in bem-helper
function argsToModifiers(args) {
  if (!args.length) {
    return {}
  }

  const modifiers = args.length === 1 ? args[0] : args

  if (typeof modifiers === 'string') {
    return { [modifiers]: true }
  }

  if (Array.isArray(modifiers)) {
    return fromPairs(modifiers.map(modifier => [modifier, true]))
  }

  return modifiers
}

// Component definition
export default class Component extends React.Component {
  constructor(props, bem) {
    super(props)

    this.bindAll()

    this.bem = Bem(bem || 'component')
  }

  bindAll() {
    Object.getOwnPropertyNames(this.constructor.prototype).forEach(
      this.bind.bind(this)
    )
  }

  bind(name) {
    const bindable =
      this[name] !== this.constructor && typeof this[name] === 'function'

    if (bindable) {
      this[name] = this[name].bind(this)
    }
  }

  b(...args) {
    const m = Object.assign({}, this.m(), argsToModifiers(args))

    return this.bem.m(m).and(this.props.className).c()
  }

  e(name, ...args) {
    const m = argsToModifiers(args)

    return this.bem.e(name).m(m).c()
  }

  m() {
    return {}
  }

  propsFor(source) {
    if (Array.isArray(source)) {
      return pick(this.props, source)
    }

    if (typeof source.propTypes === 'function') {
      return pick(this.props, source.propTypes())
    }

    return {}
  }

  static props(props, defaults) {
    if (defaults) {
      this.propTypes = props
      this.defaultProps = defaults
      return
    }

    if (props.propTypes && props.defaultProps) {
      this.propTypes = props.propTypes()
      this.defaultProps = props.defaultProps()
      return
    }

    this.propTypes = propTypes(props)
    this.defaultProps = defaultProps(props)
  }
}
