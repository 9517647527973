import React from 'react'

import PropTypes from 'prop-types'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Card from 'app/components/Card/Card'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import { IconModel } from 'app/components/Icon/Icon'
import RichText from 'app/components/RichText/RichText'
import { DisclaimerCta } from '@shaw/react-component-library'
import ProductLineModel from 'app/models/ProductLine/ProductLine'
import ProductFeatureListItem from 'app/components/ProductFeatureListItem/ProductFeatureListItem'

import './ProductOfferCard.scss'

// Component model definition
export class ProductOfferCardModel extends Model {
  constructor(d, parentName = false) {
    super(d)
    // From the CMS
    this.productLine =
      d && d.productLine ? new ProductLineModel(d.productLine) : undefined
    this.cta = d && d.cta ? new LinkModel(d.cta) : undefined

    const linkName = this.productLine
      ? this.productLine.productPlanPage.text
      : ''

    const analyticsName = d ? d.name : ''

    // Computed
    this.analyticsEvent = this.productLine
      ? this.productLine.productPlanPage.determineAnalyticsEvent()
      : ''
    this.analyticsValue = parentName
      ? `${slug(parentName)}|${slug(analyticsName)}|${slug(linkName)}`
      : `${slug(analyticsName)}|${slug(linkName)}`

    if (this.productLine && this.productLine.productPlanPage.analyticsEvent) {
      this.analyticsEvent = this.productLine.productPlanPage.analyticsEvent
    }
  }

  static props() {
    return {
      // From the CMS
      name: String,
      productLine: ProductLineModel.shape,
      superscript: String,
      hidePriceLine: Boolean,
      pricePrefix: String,
      priceOverride: String,
      priceTextOverride: String,

      // Derived
      analyticsEvent: String,
      analyticsValue: String,

      // From the parent component
      isHorizontal: PropTypes.bool
    }
  }

  static defaultProps() {
    return {
      ...super.defaultProps(),
      hidePriceLine: false,
      productOffer: ProductLineModel.defaultProps(),
      isHorizontal: false,
      icon: IconModel.defaultProps(),
      cta: LinkModel.defaultProps()
    }
  }
}

// Component definition
class ProductOfferCard extends Component {
  constructor(props) {
    super(props, 'product-offer-card')
  }

  m() {
    return {
      scotch: true,
      vertical: !this.props.isHorizontal
    }
  }

  render() {
    if (!this.props.productLine) {
      console.warn(
        `Use of Model - Product Offer has been deprecated in favor of Model - Product Line.  ` +
          `Skipping Render of : ${this.props.name}`
      )
      return null
    }

    const {
      productLine,
      isHorizontal,
      analyticsValue,
      analyticsEvent,
      priceOverride,
      priceTextOverride,
      pricePrefix,
      superscript
    } = this.props

    let pricePoint = productLine.lowestPrice

    if (priceOverride && priceTextOverride) {
      pricePoint.price = priceOverride
      pricePoint.text = priceTextOverride
    }

    const hidePriceLine = this.props.hidePriceLine
    const regionalPricePoint =
      pricePoint && pricePoint[this.province] ? pricePoint[this.province] : {}

    return (
      <Card
        className={this.b()}
        isHorizontal={isHorizontal}
        icon={productLine.icon}
      >
        <div className={this.e('block')}>
          <h3 className={this.e('title')}>
            <RichText markup={productLine.stylizedName} isMarkdown />
          </h3>
          <RichText
            renderers={{ root: 'p' }}
            className={this.e('short-description')}
            markup={productLine.shortDescription}
          />

          <div
            className={this.e(
              'features',
              hidePriceLine ? 'no-bottom-line' : undefined
            )}
          >
            <ul className={this.e('features-list')}>
              {productLine.lineFeatures.map(feature => (
                <ProductFeatureListItem key={uuid()} {...feature} />
              ))}
            </ul>
          </div>
        </div>
        <div className={`${this.e('block')} bottom`}>
          {!hidePriceLine && (
            <div className={this.e('price-block')}>
              <p className={this.e('text-above')}>{pricePrefix}</p>
              <p className={this.e('price')}>
                <sup className={this.e('price-symbol')}>$</sup>
                {regionalPricePoint.price || pricePoint.price}
                <span className={this.e('per-text')}>
                  /mo
                  <DisclaimerCta
                    className={this.e('asterisk-symbol')}
                    href={superscript}
                  >
                    {superscript}
                  </DisclaimerCta>
                </span>
              </p>

              <RichText
                className={this.e('text-below')}
                markup={regionalPricePoint.text || pricePoint.text}
                isMarkdown
              />
            </div>
          )}
          <Button
            className={this.e('button')}
            {...productLine.productPlanPage.asButton}
            design={'scotch-primary'}
            variant={'light'}
            analyticsEvent={analyticsEvent}
            analyticsValue={analyticsValue}
          />
        </div>
      </Card>
    )
  }
}

ProductOfferCard.props(ProductOfferCardModel)
export default ProductOfferCard
