import React from 'react'

import { v1 as uuid } from 'uuid'
import PropTypes from 'prop-types'

import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import Eyebrow from 'app/components/Eyebrow/Eyebrow'
import Icon, { IconModel } from 'app/components/Icon/Icon'
import RichText, { RichTextModel } from 'app/components/RichText/RichText'

import './BasicIconBox.scss'

// Component model definition
export class BasicIconBoxModel {
  constructor(d) {
    this.name = d.name
    this.icon = new IconModel(d.icon)
    this.title = d.title
    this.eyebrow = d.eyebrow
    this.description = d.description
    this.cta = d.cta ? new LinkModel(d.cta) : null
    this.ctaAlt = d.ctaAlt ? new LinkModel(d.ctaAlt) : null
  }

  static propTypes() {
    return {
      cta: PropTypes.shape(LinkModel.propTypes()),
      ctaAlt: PropTypes.shape(LinkModel.propTypes()),
      eyebrow: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.shape(IconModel.propTypes()),
      title: PropTypes.string,
      styles: PropTypes.shape(),
      trackPage: PropTypes.string,
      trackModuleName: PropTypes.string,
      trackRadio: PropTypes.string
    }
  }

  static defaultProps() {
    return {
      icon: IconModel.defaultProps(),
      title: '',
      description: '',
      trackPage: '',
      trackModuleName: '',
      trackRadio: ''
    }
  }
}

// Component definition
export default class BasicIconBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentPage: '' }
    this.b = Bem('basic-icon-box')
  }
  componentDidMount() {
    const location = typeof window !== 'undefined' ? window.location : null
    const currentPage = location && location.pathname
    setTimeout(() => {
      this.setState({ currentPage: this.props.trackPage || currentPage })
    }, 100)
  }

  render() {
    const { eyebrow, title, className, icon, styles } = this.props
    const description =
      this.props.description && this.props.description.trim().length > 0
        ? new RichTextModel(this.props.description)
        : null
    const boxTitle = new RichTextModel(title)

    return (
      <div className={this.b.and(className).classes()} style={styles}>
        <div className={this.b.e('icon').classes()}>
          <Icon {...icon} />
        </div>
        {eyebrow && <Eyebrow key={uuid()} text={eyebrow} />}
        {title.trim() && (
          <RichText
            key={uuid()}
            className={this.b.e('title').classes()}
            {...boxTitle}
            renderers={{ root: 'h3' }}
            data-testid="box-title"
          />
        )}
        {description && (
          <RichText
            key={uuid()}
            className={this.b.e('description').classes()}
            {...description}
            data-testid="box-description"
          />
        )}
        {this.renderButton()}
        {this.renderLink()}
      </div>
    )
  }
  renderButton() {
    const {
      cta,
      analyticsEvent,
      analyticsValue,
      title,
      trackModuleName,
      trackRadio
    } = this.props
    const { currentPage } = this.state
    const trckPage = slug(currentPage)
    const trckModule = trackModuleName || `${slug(title || 'icon box cta')}|`
    const dataEvent =
      analyticsEvent || (cta && cta.analyticsEvent) || 'navigationAction'
    const dataValue = `${trackRadio}${
      analyticsValue || (cta && cta.accessibilityLabel)
    }`
    if (cta) {
      const ctaLink = new LinkModel(cta)
      const button = {
        ...ctaLink.asButton,
        analyticsEvent: `${dataEvent}`,
        analyticsValue: `${trckPage}${trckModule}${slug(dataValue)}`.replace(
          '||',
          '|'
        )
      }
      return (
        <Button
          key={uuid()}
          {...button}
          className={this.b.e('cta-button').classes()}
        />
      )
    } else {
      return null
    }
  }
  renderLink() {
    const { ctaAlt, analyticsEvent, analyticsValue, title } = this.props
    const { currentPage } = this.state
    if (ctaAlt) {
      const ctaLink = new LinkModel(ctaAlt)
      const trackPage = slug(currentPage).slice(1)
      const dataEvent =
        analyticsEvent || ctaAlt.analyticsEvent || 'navigationAction'
      const dataValue = analyticsValue || ctaAlt.accessibilityLabel || ''
      const button = {
        ...ctaLink.asButton,
        icon: ctaLink.icon,
        design: 'link',
        variant: '',
        analyticsEvent: `${dataEvent}`,
        analyticsValue: `${trackPage}|${slug(
          title || 'icon box link'
        )}|${dataValue}`
      }
      const currentLink = ctaLink.href || ctaLink.target.canonicalUrl
      return currentLink === currentPage ? null : (
        <Button
          key={uuid()}
          {...button}
          className={this.b.e('cta-link').classes()}
        />
      )
    } else {
      return null
    }
  }
}

BasicIconBox.propTypes = BasicIconBoxModel.propTypes()
BasicIconBox.defaultProps = BasicIconBoxModel.defaultProps()
