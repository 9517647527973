import React from 'react'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import Chevron from 'app/components/Chevron/Chevron'
import { ReactHeight } from '@shaw/react-component-library'

import './CollapsableCard.scss'

// Component model definition
export class CollapsableCardModel extends Model {
  static props() {
    return {
      isOpen: {
        type: Boolean,
        required: true
      },
      showButton: {
        type: Boolean,
        required: false,
        default: true
      },
      toggleCard: {
        type: Function,
        required: false
      },
      analyticsEvent: {
        type: String,
        required: true,
        default: 'helpfulAction'
      },
      parentModuleName: {
        type: String,
        required: true,
        default: 'parentModule'
      }
    }
  }
}

// Component definition
export default class CollapsableCard extends Component {
  constructor(props) {
    super(props, 'collapsable-card')

    this.state = {
      height: 0,
      dirty: true
    }
  }

  componentDidMount() {
    if (global && global.addEventListener) {
      global.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    if (global && global.removeEventListener) {
      global.removeEventListener('resize', this.handleResize)
    }
  }

  onHeightReady = height => {
    this.setState({ height, dirty: false })
  }

  get style() {
    return this.props.isOpen ? { maxHeight: this.state.height, opacity: 1 } : {}
  }

  m() {
    return {
      scotch: true,
      open: this.props.isOpen,
      collapsable:
        typeof this.props.renderCollapsable === 'function' &&
        this.props.renderCollapsable() !== null
    }
  }

  handleResize = () => {
    this.setState({ dirty: true })
  }

  render() {
    const { isOpen } = this.props
    const { renderCollapsable } = this.props
    let showButton = this.props.showButton && renderCollapsable !== null
    if (typeof renderCollapsable === 'function' && !renderCollapsable()) {
      showButton = false
    }
    return (
      <article className={this.b()}>
        <div className={this.e('container')}>
          <div className={this.e('main-content')}>
            {typeof this.props.renderMain === 'function'
              ? this.props.renderMain()
              : this.props.renderMain}
            {showButton && (
              <div className={this.e('toggle-container')}>
                <button
                  type="button"
                  className={this.e('toggle')}
                  onClick={this.props.toggleCard}
                  data-event={this.props.analyticsEvent}
                  data-value={`${this.props.parentModuleName}|${
                    isOpen ? 'contract' : 'expand'
                  }`}
                >
                  <span className={this.e('icon-container')}>
                    <Chevron open={this.m().open} blue />
                  </span>
                </button>
              </div>
            )}
          </div>
          <div style={this.style} className={this.e('overflow-content')}>
            {this.props.renderCollapsable ? (
              <ReactHeight
                dirty={isOpen ? false : this.state.dirty}
                onHeightReady={this.onHeightReady}
              >
                {typeof this.props.renderCollapsable === 'function'
                  ? this.props.renderCollapsable()
                  : this.props.renderCollapsable}
              </ReactHeight>
            ) : null}
          </div>
        </div>
      </article>
    )
  }
}

CollapsableCard.propTypes = CollapsableCardModel.propTypes()
CollapsableCard.defaultProps = CollapsableCardModel.defaultProps()
