import React, { useState, useEffect, useId } from 'react'

import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import Eyebrow from 'app/components/Eyebrow/Eyebrow'
import RichText from 'app/components/RichText/RichText'

import './BasicTextBox.scss'

export class BasicTextBoxModel extends Model {
  static props() {
    return {
      title: PropTypes.string,
      titleWeight: PropTypes.string,
      eyebrow: PropTypes.string,
      description: PropTypes.string,
      cta: PropTypes.shape(LinkModel.propTypes()),
      ctaAlt: PropTypes.shape(LinkModel.propTypes()),
      alignMobile: PropTypes.string,
      className: PropTypes.string,
      boxType: PropTypes.string
    }
  }

  static defaultProps() {
    return {
      description:
        'Default!  Stay _ahead_ of the *latest* default text with automatic parsing.',
      alignMobile: 'center',
      className: '',
      titleWeight: 'bold',
      boxType: 'TextBox'
    }
  }
}

export default function BasicTextBox(props = BasicTextBoxModel.defaultProps()) {
  const b = Bem('basic-text-box')
  const ctaLink = new LinkModel(props.cta)
  const ctaLink2 = new LinkModel(props.link || props.ctaAlt)
  const ctaAlt = props.link || props.ctaAlt
  const classNames = `${props.className} basic-text-box--mobile-${props.alignMobile}`
  const icon = props.cta && props.cta.icon ? props.cta.icon : null
  const icon2 = ctaAlt?.icon || null
  const button = props.cta
    ? {
        ...ctaLink.asButton,
        design: ctaLink.style || 'scotch-primary'
      }
    : null

  const button2 = ctaAlt
    ? {
        ...ctaLink2.asButton,
        design: ctaLink2.style || 'scotch-primary'
      }
    : null

  const trackModuleName = props.name
    ? `${slug(props.name.replace(/.*-/, ''))}|`
    : ''
  const trackLabel = props.cta
    ? `${slug(props.cta.additionalLabel || props.cta.label)}`
    : ''
  const [trackPage, setTrackPage] = useState('')
  useEffect(() => {
    const trackPage =
      typeof window !== 'undefined' && location.pathname !== '/'
        ? `${slug(location.pathname)}|`
        : 'homepage|'

    setTrackPage(trackPage)
  }, [])

  return (
    <div className={b.and(classNames).classes()}>
      {props.eyebrow && <Eyebrow text={props.eyebrow} align="left" />}
      {props.title && (
        <RichText
          key={useId()}
          className={b
            .e('title')
            .m({
              [props.titleWeight]: true
            })
            .classes()}
          markup={props.title}
          renderers={{ root: 'h3' }}
          data-testid="box-title"
        />
      )}
      <RichText
        key={useId()}
        className={b.e('description').classes()}
        markup={props.description}
        data-testid="box-description"
      />
      <div className={b.e('buttons').classes()}>
        {button && (
          <Button
            key={useId()}
            {...button}
            icon={icon}
            className={b.e('cta-button').classes()}
            analyticsValue={`contente|${trackPage}${trackModuleName}${trackLabel}`}
          />
        )}
        {button2 && (
          <Button
            key={useId()}
            {...button2}
            icon={icon2}
            className={b.e('cta-button').classes()}
            analyticsValue={`content|${trackPage}${trackModuleName}${trackLabel}`}
          />
        )}
      </div>
    </div>
  )
}

BasicTextBox.propTypes = BasicTextBoxModel.propTypes()
