import React from 'react'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Card from 'app/components/Card/Card'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import { BasicTextBoxModel } from 'app/components/BasicTextBox/BasicTextBox'
import BasicTextBoxStack, {
  BasicTextBoxStackModel
} from 'app/components/BasicTextBoxStack/BasicTextBoxStack'

import './ProductOfferAddOnCard.scss'

// Component model definition
export class ProductOfferAddOnCardModel extends Model {
  static props() {
    return {
      title: {
        type: String,
        required: true,
        default: 'Recommended add-ons'
      },
      addOns: {
        type: BasicTextBoxModel.arrayOf,
        required: true,
        default: [
          BasicTextBoxModel.defaultProps(),
          BasicTextBoxModel.defaultProps()
        ]
      },
      cta: {
        type: LinkModel.shape
      },
      parentName: String
    }
  }
}

// Component definition
export default class ProductOfferAddOnCard extends Component {
  constructor(props) {
    super(props, 'product-offer-add-on-card')
  }

  render() {
    const { addOns, title, name, cta, parentName } = this.props

    const stackModel = new BasicTextBoxStackModel({
      showDividers: true,
      textBoxes: addOns,
      orientation: 'row'
    })

    let button
    if (cta) {
      const link = new LinkModel(cta)

      button = {
        ...link.asButton,
        design: 'secondary',
        analyticsEvent: link.determineAnalyticsEvent(),
        analyticsValue: parentName
          ? `${parentName}|${slug(name)}|${slug(link.text)}`
          : `${slug(name)}|${slug(link.text)}`
      }
    }

    return (
      <Card className={this.b()}>
        <span className={this.e('title')}>{title}</span>
        <div className={this.e('text-box-container')}>
          <BasicTextBoxStack {...stackModel} />
        </div>
        {cta && (
          <div className={this.e('button-container')}>
            <Button className={this.e('button')} {...button} />
          </div>
        )}
      </Card>
    )
  }
}

ProductOfferAddOnCard.props(ProductOfferAddOnCardModel)
