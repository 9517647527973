import React from 'react'

import PropTypes from 'prop-types'

import Bem from 'app/utils/bem-helper'
import { IconModel } from 'app/components/Icon/Icon'
import IconBadge from 'app/components/IconBadge/IconBadge'

import './Card.scss'

// Component model definition
export class CardModel {
  constructor(d) {
    this.icon = new IconModel(d.icon)
    this.isHorizontal = d.isHorizontal
  }

  static propTypes() {
    return {
      icon: PropTypes.shape(IconModel.shape),
      isHorizontal: PropTypes.bool
    }
  }

  static defaultProps() {
    return {
      icon: null,
      isHorizontal: false
    }
  }
}

// Component definition
export default function Card(props = CardModel.defaultProps()) {
  const b = Bem('card')

  return (
    <article
      className={b
        .m({ scotch: true, vertical: !props.isHorizontal })
        .and(props.className)
        .classes()}
      aria-label={props.title}
    >
      {props.icon && (
        <div className={b.e('icon').classes()}>
          <IconBadge icon={props.icon} />
        </div>
      )}

      <div className={b.e('container').classes()}>
        <div className={b.e('content').classes()}>{props.children}</div>
      </div>
    </article>
  )
}

Card.propTypes = {
  icon: PropTypes.shape(),
  isHorizontal: PropTypes.bool
}

