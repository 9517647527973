import React, { Fragment, useId } from 'react'

import PropTypes from 'prop-types'
import Bem from 'react-bem-helper'
import Markdown from 'markdown-to-jsx'

import { Button } from 'app/components/Button/Button'
import { DisclaimerCta } from '@shaw/react-component-library'

import './RichText.scss'
export class RichTextModel {
  constructor(d = '') {
    this.markup = d
  }

  static propTypes() {
    return {
      markup: PropTypes.string,
      isMarkdown: PropTypes.bool,
      className: PropTypes.string,
      noClass: PropTypes.bool,
      renderers: PropTypes.shape()
    }
  }

  static defaultProps() {
    return {
      markup: '',
      isMarkdown: true,
      className: '',
      noClass: false,
      renderers: {}
    }
  }
}

function CustomLink({ children, href = '', ...props }) {
  if (href.match(/#\d?\d?\d|#\W/)) {
    return (
      <DisclaimerCta {...props} href={href}>
        {children}
      </DisclaimerCta>
    )
  } else {
    return (
      <a
        {...props}
        href={href}
        // data-event="navigationAction"
        // data-value={href}
      >
        {children}
      </a>
    )
  }
}

function Link({ href, text, target, className, ...rest }) {
  return (
    <a
      href={href}
      target={target}
      className={`footer-link ${className}`}
      // data-event="navigationAction"
      // data-value={href}
      {...rest}
    >
      {text}
    </a>
  )
}

function Sup({ children }) {
  return <sup>{children}</sup>
}
function Bold({ children }) {
  return <strong>{children}</strong>
}
function Italic({ children }) {
  return <em>{children}</em>
}
function Parag({ renderers, children, ...props }) {
  const elem = renderers.p || renderers.paragraph
  let rest = props

  if (elem === Fragment) {
    rest = { key: props.key }
  }
  if (elem) {
    return React.createElement(elem, rest, children)
  }
  return (
    <p {...props} key={`${props.key}-${useId()}`}>
      {children}
    </p>
  )
}

function Table({ children, ...props }) {
  return <table {...props}>{children}</table>
}

function RichTextWrapper({ withContainer, children }) {
  return withContainer ? (
    <div className="rich-text-container">{children}</div>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

function Image({ ...props }) {
  return (
    <img
      {...props}
      key={`${props.key}-${useId()}`}
      alt={props.alt || 'image alt attribute'}
      style={{
        display: 'inline-flex'
      }}
    />
  )
}

function LI({ children, ...props }) {
  return (
    <li {...props}>
      <span>{children}</span>
    </li>
  )
}

export default function RichText({
  markup,
  disclaimer,
  isMarkdown,
  className,
  noClass = false,
  withContainer = false,
  renderers = {},
  ...props
}) {
  if (!markup) return null

  const classes = new Bem({
    name: 'rich-text',
    prefix: ''
  })

  const classProps = noClass ? {} : { ...classes({ extra: className }) }

  return (
    <RichTextWrapper withContainer={withContainer}>
      <Markdown
        {...classProps}
        options={{
          forceWrapper: true,
          wrapper: renderers.root || 'div',
          overrides: {
            a: CustomLink,
            p: props => <Parag renderers={renderers} {...props} />,
            li: LI,
            Button: Button,
            Sup: Sup,
            SUP: Sup,
            Bold: Bold,
            Italic: Italic,
            Link: Link,
            table: Table,
            img: Image
          }
        }}
        {...props}
      >
        {markup}
      </Markdown>
    </RichTextWrapper>
  )
}
