import './PriceInformationNew.scss'

import Bem from 'app/utils/bem-helper'
import Model from 'app/models/Model'
import ProductOfferModel from 'app/models/ProductOffer/ProductOffer'
import React from 'react'
import RichText from 'app/components/RichText/RichText'
import { withCookies } from 'react-cookie'

export class PriceInformationModel extends Model {
  constructor(d) {
    super(d)
    this.productOffer = new ProductOfferModel(d.productOffer)
  }

  static props() {
    return {
      productOffer: {
        type: ProductOfferModel.shape,
        default: ProductOfferModel.defaultProps()
      },
      isPromo: { type: Boolean, default: false },
      align: { type: String, default: 'right' }
    }
  }
}

function PriceInformationNew({ isPromo, align, cookies, ...props }) {
  const productOffer = new ProductOfferModel(props.productOffer)
  const b = Bem('price-information-new')
  const province = cookies ? cookies.get('queryLocation') : ''
  const { isActivePromo, pricingText } = productOffer
  const prices = productOffer.pricing
    ? productOffer.pricing.reduce((acc, price) => {
        const regionalPrice = price[province] ? price[province] : price
        return acc.concat(regionalPrice)
      }, [])
    : []

  const getPrice = () => {
    if (prices.length === 0) return null
    if (!isPromo && prices.length === 1) return null
    if (!isPromo && prices.length === 2) return prices[1].price

    return prices[0].price
  }
  if (getPrice() === null) return null

  const getDecimal = () => {
    const price = parseFloat(getPrice()).toFixed(2)
    const decimal = price && price.split('.')[1]
    return decimal && decimal !== '00' ? decimal : null
  }
  const getExtra = () => {
    const price = getPrice()
    return price ? price.replace(/[0-9\/\.]/g, '') : null
  }

  const promoBannerText =
    pricingText.promoBannerTextHtml || pricingText.promoBannerText

  return (
    <div className={b.m({ 'is-promo': isPromo }).classes()}>
      <div className={b.e('price').classes()}>
        {productOffer.termDisclaimer && isPromo && (
          <RichText
            className={b.e('term-disclaimer').classes()}
            renderers={{ root: 'span' }}
            markup={productOffer.termDisclaimer}
          />
        )}
        <span className={b.e('amount').classes()}>
          ${parseInt(getPrice(), 10)}
        </span>
        <div className={b.e('price-details').classes()}>
          {getDecimal() && (
            <div className={b.e('decimal').m({ extra: getExtra() }).classes()}>
              {getDecimal()}
            </div>
          )}
          <div
            className={b
              .e('divider')
              .m({ padding: getDecimal(), extra: getExtra() })
              .classes()}
          >
            /
          </div>
          {getExtra() && (
            <div className={b.e('extra').m({ extra: getExtra() }).classes()}>
              {getExtra()}
            </div>
          )}

          <div className={b.e('term').m({ extra: getExtra() }).classes()}>
            {productOffer.term || 'mo'}
            {productOffer.pricingText.customDisclaimer && (
              <RichText
                renderers={{ root: 'span' }}
                markup={productOffer.pricingText.customDisclaimer}
              />
            )}
          </div>
        </div>
        {isPromo ? (
          <RichText
            isMarkdown
            className={b.e('promo-text').classes()}
            markup={`${pricingText.standardPriceText || ''} ${
              pricingText.newCustomerText || ''
            }`}
          />
        ) : (
          <RichText
            isMarkdown
            className={b.e('promo-text').classes()}
            markup={pricingText.regularPriceText || ''}
          />
        )}
      </div>
      {isActivePromo && isPromo && promoBannerText && (
        <div
          className={b
            .e('offer')
            .m({ 'left-align': align === 'left' })
            .classes()}
        >
          <RichText
            isMarkdown
            className={b.e('offer-text').classes()}
            markup={promoBannerText}
            renderers={{ root: 'p' }}
          />
        </div>
      )}
    </div>
  )
}

export default withCookies(PriceInformationNew)
