import React from 'react'

import Draggable from 'react-draggable'

import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import Component from 'app/components/Component'
import { breakpoints } from 'app/utils/constants'
import ImageSelectorItemModel from 'app/models/ImageSelectorItem/ImageSelectorItem'

import './ScrollableSelector.scss'

export class ScrollableSelectorModel extends Model {
  static props() {
    return {
      collection: {
        type: ImageSelectorItemModel.arrayOf,
        required: true,
        exclude: true,
        default: [ImageSelectorItemModel.defaultProps()]
      },
      selectHandler: {
        type: Function,
        required: true,
        exclude: true,
        default: () => {}
      },
      selectedIndex: {
        type: Number,
        required: true,
        exclude: true,
        default: 0
      },
      hideSubCopy: {
        type: Boolean,
        default: false,
        exclude: true
      },
      parentModuleName: {
        type: String,
        required: true,
        exclude: true,
        default: 'parent-module'
      }
    }
  }
}

// Component definition
export default class ScrollableSelector extends Component {
  constructor(props) {
    super(props, 'scrollable-selector')
    this.state = {
      selectIndicatorStyle: {
        height: '0px',
        transform: 'translateY(0px)'
      },
      breakpoint: null,
      bounds: 'parent'
    }

    this.collectionNodes = []
    this.containerRef = React.createRef()
    this.outerRef = React.createRef()
  }

  componentDidMount() {
    setTimeout(this.calculateBounds, 1000)
    setTimeout(() => {
      if (global && global.addEventListener) {
        this.updateBreakpoint()
        global.addEventListener('resize', this.updateBreakpoint)
      }
      if (this.collectionNodes[0] != null) {
        this.changeSelectIndicator(
          this.collectionNodes[0].clientHeight,
          this.collectionNodes[0].offsetTop
        )
      }
    }, 0) // Waiting the DOM to be fully rendered with CSS
  }

  m() {
    return {
      'hide-subcopy': this.props.hideSubCopy,
      'center-selector': this.state.bounds.left === 0
    }
  }

  calculateBounds() {
    let bounds = { right: 0 }
    const offset = this.containerRef.offsetWidth - this.outerRef.offsetWidth
    bounds.left = -offset // The gutters and margin respectively, shouldn't hardcode.  TODO
    if (offset < 1) {
      bounds.left = 0
    }

    this.setState({
      bounds
    })
  }

  updateBreakpoint() {
    this.calculateBounds()
    if (global && global.addEventListener) {
      const width =
        global.innerWidth ||
        global.document.documentElement.clientWidth ||
        global.document.body.clientWidth

      let currentBreak

      for (const size in breakpoints) {
        if (width >= breakpoints[size].min && width <= breakpoints[size].max) {
          currentBreak = size
          break
        }
      }

      if (this.state.breakpoint !== currentBreak) {
        this.setState({
          breakpoint: currentBreak
        })
      }
    }
  }

  optionSelectionHandler(event) {
    const index = Number(event.currentTarget.dataset.index)
    const currentSelection = this.collectionNodes[index]

    if (currentSelection != null) {
      this.changeSelectIndicator(
        currentSelection.clientHeight,
        currentSelection.offsetTop
      )
    }

    this.props.selectHandler(event)
  }

  changeSelectIndicator(height, offset) {
    this.setState({
      selectIndicatorStyle: {
        height: `${height}px`,
        transform: `translateY(${offset}px)`
      }
    })
  }

  getContainerRef() {
    return this.containerRef
  }

  getOuterRef() {
    return this.outerRef
  }

  getCollectionNodesRefs(i) {
    return ref => {
      if (ref) {
        this.collectionNodes[i] = ref
      }
    }
  }

  renderDraggable() {
    return (
      <Draggable axis="x" bounds={this.state.bounds}>
        {this.renderContents()}
      </Draggable>
    )
  }

  renderContents() {
    const { collection, selectedIndex, children, parentModuleName } = this.props
    return (
      <div className={this.e('container')}>
        <div className={this.e('scrolling-container')} ref={this.containerRef}>
          <div className={this.e('select-indicator')}>
            <div
              className={this.e('select-indicator-element')}
              style={this.state.selectIndicatorStyle}
            />
          </div>
          {collection.map((option, index) => {
            const selectedClass =
              selectedIndex === index ? this.e('item--selected') : ''

            return (
              <button
                key={`${option}--${index}`}
                className={`${this.e('item')} ${selectedClass}`}
                onClick={this.optionSelectionHandler}
                data-index={index}
                data-subcopy={option.subCopy}
                data-event="contentAction"
                data-value={`${slug(parentModuleName)}|${slug(option.title)}`}
                // ref={this.getCollectionNodesRefs(index)}
              >
                <h3 className={this.e('button')}>{option.title}</h3>
                {option.subCopy && (
                  <p className={this.e('sub-copy')}>{option.subCopy}</p>
                )}
              </button>
            )
          })}
        </div>
        {children}
      </div>
    )
  }

  render() {
    return (
      <div className={this.b()} ref={this.outerRef}>
        {this.state.breakpoint === 'small'
          ? this.renderDraggable()
          : this.renderContents()}
      </div>
    )
  }
}

ScrollableSelector.props(ScrollableSelectorModel)
