import React from 'react'

import { throttle } from 'lodash'

import Component from 'app/components/Component'
import Sticky from 'app/components/Sticky/Sticky'
import RichText from 'app/components/RichText/RichText'
import Scroll from 'react-scroll'
const Scroller = Scroll.scroller

// Component requirements
import './StickyNav.scss'

export default class StickyNav extends Component {
  constructor(props) {
    super(props, 'sticky-nav')
    this.state = {
      itemsPos: [],
      activeIdx: -1
    }

    this.onScroll = this.onScroll.bind(this)
    this.onResize = this.onResize.bind(this)

    this.throttledResize = throttle(this.onResize, 200)
    this.throttledScroll = throttle(this.onScroll, 200)

    this.stickybar = React.createRef()
  }

  findPosY(node) {
    if (!node) {
      return 0
    }
    let curtop = 0
    if (node.offsetParent) {
      do {
        curtop += node.offsetTop
        node = node.offsetParent
      } while (node !== null)
    }
    return curtop
  }

  setItemsPositions() {
    const arr = []
    this.props.items &&
      this.props.items.map((l, idx) => {
        arr.push({
          link: l,
          idx,
          posY: this.findPosY(document.getElementById(l.href.substr(1)))
        })
      })
    this.setState({
      itemsPos: arr
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.throttledScroll)
    window.addEventListener('resize', this.throttledResize)
    this.setItemsPositions()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledScroll)
    window.removeEventListener('resize', this.throttledResize)
  }

  onResize() {
    this.setItemsPositions()
  }

  onScroll() {
    const l = this.state.itemsPos.length
    if (l === 0) {
      return
    }

    const wy = window.pageYOffset + 200 + this.stickybar.current.clientHeight

    for (var i = l - 1; i >= 0; --i) {
      const link = this.state.itemsPos[i]
      if (link.posY > 0 && wy >= link.posY) {
        if (this.state.activeIdx !== link.idx) {
          this.setState({ activeIdx: link.idx })
        }
        return
      }
    }

    this.setState({ activeIdx: -1 })
  }

  onLinkClicked(e, link) {
    e.preventDefault()
    Scroller.scrollTo(link.href.substr(1), {
      duration: 300,
      offset: this.stickybar.current.clientHeight * -1,
      smooth: true
    })
  }

  renderStickyNavItems() {
    return this.props.items.map((l, idx) => {
      return (
        <li
          key={idx}
          className={this.e(
            'item',
            idx === this.state.activeIdx ? 'active' : ''
          )}
        >
          <a href={l.href} onClick={e => this.onLinkClicked(e, l, idx)}>
            <RichText markup={l.label} />
          </a>
        </li>
      )
    })
  }

  render() {
    if (!this.props.items) {
      return null
    }

    return (
      <Sticky style={{ zIndex: 100 }}>
        <div className={this.b()} ref={this.stickybar}>
          <div className={this.e('sticky-nav-body', this.props.bgStyle)}>
            <div className={this.e('items', this.props.itemsStyle)}>
              <ul>{this.renderStickyNavItems()}</ul>
            </div>
          </div>
        </div>
      </Sticky>
    )
  }
}
