import React from 'react'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import Icon, { IconModel } from 'app/components/Icon/Icon'
import RichText, { RichTextModel } from 'app/components/RichText/RichText'

import './ProductFeatureListItem.scss'

// Component model definition
export class ProductFeatureListItemModel extends Model {
  constructor(d) {
    super(d)
    this.icon = new IconModel(d.icon)
  }

  static props() {
    return {
      icon: {
        type: IconModel.shape,
        required: true,
        default: {
          type: 'email'
        }
      },
      description: {
        type: String,
        required: true,
        default: 'I am a feature.'
      }
    }
  }
}

// Component definition
export default class ProductFeatureListItem extends Component {
  constructor(props) {
    super(props, 'product-feature-item')
  }

  render() {
    const description = new RichTextModel(this.props.description)
    return (
      <li className={this.b()}>
        <Icon className={this.e('icon')} {...this.props.icon} />
        <RichText className={this.e('description')} {...description} />
      </li>
    )
  }
}

ProductFeatureListItem.props(ProductFeatureListItemModel)
