import React, { useEffect, useMemo, useState } from 'react'

import Bem from 'react-bem-helper'
import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import RichText from 'app/components/RichText/RichText'

import './Checkbox.scss'

// Component model definition
export class CheckboxModel extends Model {
  static props() {
    return {
      id: String,
      name: String,
      label: String,
      initial: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      requiredError: String,
      infoText: String,
      selected: String,
      deselected: String,
      form: Object,
      skipInitializeValue: {
        type: Boolean,
        default: false
      },
      onChange: {
        type: Function,
        default: (name, value, form) => {
          form.set(name, value)
        }
      },
      onFormChange: {
        type: Function,
        default: () => {}
      },
      onBlur: {
        type: Function,
        default: () => {}
      }
    }
  }
}

const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])
  return isMounted
}

export default function Checkbox({
  label,
  id,
  name,
  infoText,
  initial,
  ...props
}) {
  const [selected, setSelected] = useState(initial || false)
  const [hasFocus, setFocus] = useState(false)
  const isMounted = useIsMounted()
  useMemo(
    () => (isMounted ? setSelected(initial) : undefined),
    [isMounted, initial]
  )

  const classes = new Bem('checkbox')

  const handleFocus = () => {
    setFocus(true)
  }

  const onChange = evnt => {
    const selected = evnt.target.checked
    setSelected(selected)
    props.onChange(id, selected, props.form)
    if (props.onFormChange) {
      props.onFormChange()
    }
  }

  const handleBlur = evnt => {
    const selected = evnt.target.checked
    setFocus(false)
    props.onBlur(id, selected)
  }

  return (
    <div {...classes({ 'has-focus': hasFocus })} aria-label="checkbox">
      <label
        htmlFor={id}
        {...classes('label', { checked: isMounted ? selected : '' })}
      >
        <input
          checked={selected}
          {...classes('input')}
          aria-label={label}
          aria-checked={selected}
          id={id}
          name={name || id}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="checkbox"
        />
        <RichText markup={label} isMarkdown renderers={{ root: 'span' }} />
      </label>
      {infoText && selected && <span {...classes('info')}>{infoText}</span>}
    </div>
  )
}

Checkbox.defaultProps = {
  initial: false,
  required: false,
  skipInitializeValue: false,
  onChange: (name, value, form) => {
    form.set(name, value)
  },
  onFormChange: () => {},
  onBlur: () => {}
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  initial: PropTypes.bool,
  required: PropTypes.bool,
  requiredError: PropTypes.string,
  infoText: PropTypes.string,
  selected: PropTypes.string,
  deselected: PropTypes.string,
  form: PropTypes.shape(),
  skipInitializeValue: PropTypes.bool,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  onBlur: PropTypes.func
}
