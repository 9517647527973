import React from 'react'

import PropTypes from 'prop-types'

import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'
import Icon, { IconModel } from 'app/components/Icon/Icon'

import './ProductFeatureList.scss'
export default function ProductFeatureList({ items }) {
  const b = Bem('product-feature-list')

  if (!items.length) return null

  return (
    <ul className={b.classes()}>
      {items.map(item => {
        const icon = new IconModel(item.icon)
        if (item.description.trim()) {
          return (
            <li key={item.entryId} className={b.e('item').classes()}>
              {icon && icon.type && <Icon {...item.icon} />}
              <RichText
                renderers={{ root: 'span' }}
                markup={item.description}
              />
            </li>
          )
        }
      })}
    </ul>
  )
}

ProductFeatureList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape())
}
