import React from 'react'

import SVG from 'react-inlinesvg'

import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import { FeatureList } from '@shaw/react-component-library'

import './FeatureList.scss'

export class FeatureListModel extends Model {
  constructor(d) {
    super(d)
  }

  static props() {
    return {
      name: String,
      eyebrow: {
        type: String,
        required: false
      },
      title: {
        type: String,
        required: true,
        default: ''
      },
      description: {
        type: String,
        required: false,
        default: ''
      },
      iconBox: Array,
      button: LinkModel.shape,
      background: { type: String, default: 'white' },
      border: { type: Boolean, default: true },
      margin: { type: Boolean, default: false }
    }
  }
}

// Component definition
export default function FeatureListComponent(props) {
  return (
    <FeatureList
      {...props}
      items={props.iconBox}
      theme="scotch"
      background={props.background}
      IconComponent={SVG}
    />
  )
}
