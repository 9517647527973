import React from 'react'

import PropTypes from 'prop-types'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import Eyebrow from 'app/components/Eyebrow/Eyebrow'
import RichText from 'app/components/RichText/RichText'
import ChatButton from './ChatButton'
import './SimpleContact.scss'

// Component model definition
export class SimpleContactModel extends Model {
  constructor(d) {
    super(d)
  }

  static props() {
    return {
      name: String,
      eyebrow: String,
      title: {
        type: String,
        required: true,
        default: 'SimpleContact Title'
      },
      description: {
        type: String,
        required: false,
        default: ''
      },
      background: {
        type: String,
        default: 'gradient'
      },
      links: { type: PropTypes.arrayOf(LinkModel.shape), default: [] }
    }
  }
}

// Component definition
export default class SimpleContact extends Component {
  constructor(props) {
    super(props)
    this.b = Bem('simple-contact')
  }

  render() {
    const { title, description, background, links, name, eyebrow } = this.props
    let bgr = background === 'rogers' ? 'scotch' : background

    return (
      <div className={this.b.m({ [bgr]: true }).classes()}>
        <div className={this.b.e('content').classes()}>
          {eyebrow && <Eyebrow text={eyebrow} />}
          <h3 className={this.b.e('title').classes()}>{title}</h3>
          {description && (
            <RichText
              isMarkdown
              className={this.b.e('description').classes()}
              markup={description}
            />
          )}

          <div
            className={`${this.b.e('links').classes()} cols-${links.length}`}
          >
            {links.map(l => {
              const link = new LinkModel(l)
              const icon = l.icon
              const button = {
                ...link.asButton,
                design: '',
                analyticsEvent: link.determineAnalyticsEvent(),
                analyticsValue: `${slug(name)}|${slug(link.text)}`
              }
              if (link.type === 'phone') {
                button.design = 'link'
              }
              if (link.type === 'chat') {
                return (
                  <ChatButton
                    className={this.b.e('link-item').classes()}
                    key={l.entryId}
                    {...button}
                    icon={icon}
                  />
                )
              }

              return (
                <Button
                  className={this.b.e('link-item').classes()}
                  key={l.entryId}
                  {...button}
                  icon={icon}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

SimpleContact.props(SimpleContactModel)
