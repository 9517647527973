import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import Component from 'app/components/Component'
import Hero, { HeroModel } from 'app/components/Hero/Hero'
import GradientBackground from 'app/components/GradientBackground/GradientBackground'

import './GradientHero.scss'

export class GradientHeroModel extends Model {
  static props() {
    return {
      ...HeroModel.props(),
      entryId: String,
      breadcrumbs: [Symbol, Object]
    }
  }
}

// Component definition
export default class GradientHero extends Component {
  constructor(props) {
    super(props, 'gradient-hero')
    this.b = Bem('gradient-hero')
  }

  render() {
    const heroProps = new HeroModel(this.props)
    const classes = this.b.m({ 'with-breadcrumbs': this.props.breadcrumbs }).c()

    return (
      <GradientBackground
        className={`${classes}`}
        theme={heroProps.theme || 'scotch'}
      >
        <Hero
          {...heroProps}
          name={heroProps.name || 'Hero'}
          breadcrumbs={this.props.breadcrumbs || null}
          heroClassName={`${this.props.breadcrumbs ? 'with-breadcrumbs' : ''}`}
        />
      </GradientBackground>
    )
  }
}

GradientHero.props(GradientHeroModel)
