import React from 'react'

import PropTypes from 'prop-types'
import Bem from 'react-bem-helper'
import Model from 'app/models/Model'
import ImageModel from 'app/models/Image/Image'
import { breakpoints } from 'app/utils/constants'
import { canUseDOM } from 'app/utils/env-helpers'

import './ResponsiveImage.scss'

// Component model definition
export class ResponsiveImageModel extends Model {
  constructor(d) {
    super(d)
    this.small = d ? new ImageModel(d.small || d.medium || d.large) : null
    this.medium = d ? new ImageModel(d.medium || d.large || d.small) : null
    this.large = d ? new ImageModel(d.large || d.medium || d.small) : null
  }

  get currentImage() {
    if (!canUseDOM()) {
      return this.large
    }

    const width =
      global.innerWidth ||
      global.document.documentElement.clientWidth ||
      global.document.body.clientWidth

    for (const size in breakpoints) {
      if (width >= breakpoints[size].min && width <= breakpoints[size].max) {
        return this[size]
      }
    }

    return null
  }

  static props() {
    const imageProps = {
      src: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
      mime: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string
    }
    return {
      small: PropTypes.shape(imageProps),
      medium: PropTypes.shape(imageProps),
      large: PropTypes.shape(imageProps),
      breakpoints: PropTypes.shape(),
      onSwitch: PropTypes.func,
      useImageSizes: PropTypes.bool,
      useRetina: PropTypes.bool,
      className: PropTypes.string
    }
  }
}

export const formatImage = (src, variant) => {
  if (!src) return null
  if (src.includes('w=')) return src
  if (variant === 'small') {
    return src.includes('?') ? `${src}&w=500` : `${src}?w=500`
  } else if (variant === 'medium') {
    return src.includes('?') ? `${src}&w=820` : `${src}?w=820`
  }
  return src.includes('?') ? `${src}&w=1280` : `${src}?w=1280`
}

export default function ResponsiveImage(props) {
  const image = new ResponsiveImageModel(props)
  const classes = new Bem('responsive-image')
  return (
    <div {...classes({ extra: props.className })}>
      <picture>
        <source
          media="(min-width: 769px)"
          srcSet={formatImage(image?.large?.src, 'large')}
        />
        <source
          media="(min-width: 431px)"
          srcSet={formatImage(image?.medium?.src, 'medium')}
        />
        <img
          src={formatImage(image?.small?.src, 'small')}
          alt={props.name}
          data-testid="responsive-image"
        />
      </picture>
    </div>
  )
}


// class ResponsiveImage extends Component {
//   constructor(props) {
//     super(props, 'responsive-image')
//     this.processImages(props)

//     let imageBreak = 'large'
//     if (props.browser) {
//       if (props.browser.tablet) {
//         imageBreak = 'medium'
//       } else if (
//         props.browser.ios ||
//         props.browser.android ||
//         props.browser.windowsphone ||
//         props.browser.mobile
//       ) {
//         imageBreak = 'small'
//       }
//     }
//     this.state = {
//       image: imageBreak
//     }
//   }

//   componentDidMount() {
//     if (global && global.addEventListener) {
//       global.addEventListener('resize', this.onResize)
//       this.onResize()
//       if (this.props.onSwitch) {
//         this.props.onSwitch(this.state.image)
//       }
//     }
//   }

//   componentDidUpdate(nextProps) {
//     this.processImages(nextProps)
//     this.onResize()
//   }

//   processImages(props) {
//     if (this.sizeValidations(props)) {
//       this.images = {
//         small: new ImageModel(
//           this.props.small || this.props.medium || this.props.large
//         ),
//         medium: new ImageModel(
//           this.props.medium || this.props.large || this.props.small
//         ),
//         large: new ImageModel(
//           this.props.large || this.props.medium || this.props.small
//         )
//       }
//     }
//   }

//   onResize() {
//     const width =
//       global.innerWidth ||
//       global.document.documentElement.clientWidth ||
//       global.document.body.clientWidth

//     const breakpoints = this.props.breakpoints
//     let image

//     for (const size in breakpoints) {
//       if (width >= breakpoints[size].min && width <= breakpoints[size].max) {
//         image = size
//         break
//       }
//     }

//     if (image !== this.state.image) {
//       this.setState({ image })
//       if (this.props.onSwitch) {
//         this.props.onSwitch(image)
//       }
//     }
//   }

//   sizeValidations(params) {
//     return params.small || params.medium || params.large
//   }

//   formatImage = src => {
//     let image = src
//     if (this.state.image === 'small') {
//       if (src.match(/jpg/)) {
//         image = `${src}&w=375`
//       } else if (src.match(/avif/)) {
//         image = `${src}?w=375`
//       }
//     } else if (this.state.image === 'medium') {
//       if (src.match(/jpg/)) {
//         image = `${src}&w=820`
//       } else if (src.match(/avif/)) {
//         image = `${src}?w=820`
//       }
//     } else if (this.state.image === 'large') {
//       if (src.match(/jpg/)) {
//         image = `${src}&w=1280`
//       } else if (src.match(/avif/)) {
//         image = `${src}?w=1280`
//       }
//     }
//     return image
//   }

//   render() {
//     const { useImageSizes, useRetina } = this.props
//     if (this.sizeValidations(this.props)) {
//       const image = this.images[this.state.image]
//       const isRetina = image.src.match(/2x/i) && useRetina

//       const dimensions = useImageSizes
//         ? {
//             width: isRetina ? image.width / 2 : image.width,
//             height: isRetina ? image.height / 2 : image.height
//           }
//         : {}

//       return (
//         <div className={`${this.b()} lazyload`} style={this.props.style}>
//           {image && (
//             <img
//               data-testid="responsive-image"
//               data-src={image.src}
//               src={this.formatImage(image.src)}
//               alt={image.description || ''}
//               {...dimensions}
//             />
//           )}
//         </div>
//       )
//     }
//     return null
//   }
// }
// ResponsiveImage.defaultProps = {
//   browser: {
//     tablet: true
//   }
// }
// ResponsiveImage.propTypes = ResponsiveImageModel.props()
// ResponsiveImage.defaultProps = {
//   breakpoints,
//   useImageSizes: true
// }
// export default connect(({ self }) => ({
//   browser: self.browser
// }))(ResponsiveImage)
