import React from 'react'

import PropTypes from 'prop-types'

import Bem from 'app/utils/bem-helper'

import './GradientBackground.scss'

// Component model definition
export class GradientBackgroundModel {
  static propTypes() {
    return {
      theme: PropTypes.string,
      className: PropTypes.string,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ]).isRequired
    }
  }

  static defaultProps() {
    return {
      className: '',
      theme: 'scotch'
    }
  }
}

// Component definition
export default class GradientBackground extends React.Component {
  constructor(props) {
    super(props)
    this.b = Bem('gradient-background')
  }

  render() {
    return (
      <div
        className={this.b
          .m({ [this.props.theme]: true })
          .and(this.props.className)
          .classes()}
      >
        {this.props.children}
      </div>
    )
  }
}

GradientBackground.propTypes = GradientBackgroundModel.propTypes()
GradientBackground.defaultProps = GradientBackgroundModel.defaultProps()
