import React from 'react'

import { connect } from 'react-redux'
import Autocomplete, { geocodeByAddress } from 'react-places-autocomplete'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import { setFormFieldValues } from 'app/actions/form'

import './FormAddressAutocomplete.scss'
// Component model definition
export class FormAddressAutocompleteModel extends Model {
  static props() {
    return {
      id: String,
      placeholder: String,
      required: Boolean,
      requiredError: String,
      cityFieldId: String,
      provinceFieldId: String,
      postalCodeFieldId: String,
      countryFieldId: String,
      form: Object,
      onFormChange: {
        type: Function,
        default: () => {}
      }
    }
  }
}

// Component definition
class FormAddressAutocomplete extends Component {
  constructor(props) {
    super(props, 'form-field')

    if (this.props.required) {
      this.props.form.require(this.props.id)
    }

    if (this.props.requiredError) {
      this.props.form.messages.add(
        this.props.id,
        'required',
        this.props.requiredError
      )
    }

    this.formName = 'form'
    this.sessionToken =
      typeof window !== 'undefined' &&
      window.google &&
      new window.google.maps.places.AutocompleteSessionToken()

    this.state = {
      focused: false,
      value: this.props.form.reset(this.props.id, this.props.defaultValue) || ''
    }

    this.props.setFormFieldValues(
      this.formName,
      this.props.id,
      this.props.defaultValue
    )

    this.autocomplete = null
    this.autoCompleteRef = React.createRef()
  }

  getFieldValue = () => {
    // return this.state.value
    return this.props.forms[this.formName]
      ? this.props.forms[this.formName][this.props.id]
      : null
  }

  fillInAddress = place => {
    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name',
      postal_code_prefix: 'short_name'
    }

    let vals = {}

    // Get the place details from the autocomplete object.

    // Get each component of the address from the place details
    // and fill the corresponding field on the form.
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0]
      if (componentForm[addressType]) {
        const val = place.address_components[i][componentForm[addressType]]
        vals[addressType] = val
      }
    }

    const transformRules = {
      street: {
        fieldId: this.props.id,
        fun: vals => {
          return `${vals['street_number'] || ''} ${vals['route'] || ''}`
        }
      },
      city: {
        fieldId: this.props.cityFieldId,
        fun: vals => {
          return `${vals['locality'] || ''}`
        }
      },
      province: {
        fieldId: this.props.provinceFieldId,
        fun: vals => {
          return `${vals['administrative_area_level_1'] || ''}`
        }
      },
      country: {
        fieldId: this.props.countryFieldId,
        fun: vals => {
          return `${vals['country'] || ''}`
        }
      },
      postal_code: {
        fieldId: this.props.postalCodeFieldId,
        fun: vals => {
          return `${vals['postal_code'] || vals['postal_code_prefix'] || ''}`
        }
      }
    }
    Object.keys(transformRules).map(key => {
      if (transformRules[key].fieldId) {
        const fieldId = transformRules[key].fieldId
        const val = transformRules[key].fun(vals) || ''
        this.props.form.set(fieldId, val)
        // document.getElementById(fieldId).value = val
        this.props.setFormFieldValues(this.formName, fieldId, val)
        this.props.onFormChange()
      }
    })

    const address = transformRules.street.fun(vals)
    this.onChange(address)
  }

  get label() {
    return this.props.placeholder
  }

  error(customError, clearSuggestions) {
    let error = ''
    if (customError) {
      error = `${customError.status}`
      clearSuggestions()
    } else if (this.props.form.errors.first(this.props.id)) {
      error = this.props.form.errors.first(this.props.id)
    }

    return error
  }

  onFocus = () => {
    this.props.form.invalidate(this.props.id)
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.props.form.validate(this.props.id)
    this.setState({ focused: false })
    this.props.onFormChange()
  }

  onChange = value => {
    this.props.setFormFieldValues(this.formName, this.props.id, value)
    this.setState({
      value: this.props.form.set(this.props.id, value)
    })
    this.props.onFormChange()
  }

  handleSelect = async address => {
    try {
      const results = await geocodeByAddress(address)
      this.fillInAddress(results[0])
    } catch (error) {
      const addr = address?.split(',') || ['']
      this.onChange(addr[0])
      console.error('------- error --------->', error)
    }
  }

  render() {
    const { id } = this.props
    const { value, focused } = this.state
    return (
      <Autocomplete
        debounce={500}
        value={value}
        onChange={this.onChange}
        onError={this.error}
        onSelect={this.handleSelect}
        shouldFetchSuggestions={focused && value.length >= 2}
        searchOptions={{
          types: ['geocode'],
          componentRestrictions: { country: 'ca' },
          fields: ['address_components', 'geometry'],
          sessionToken: this.sessionToken
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <label
            className={this.e('input-label', {
              focus: this.state.focused,
              filled: !!this.state.value,
              active:
                this.state.focused || !this.props.form.empty(this.props.id)
            })}
            htmlFor={id}
            key={`${id}-label`}
          >
            {loading && (
              <div key={`${id}-loading`} className={this.e('loading')} />
            )}
            <input
              {...getInputProps({
                id: id,
                placeholder: ''
              })}
              key={`${id}-input`}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
            <span className={this.e('span')} key={`${id}-span`}>
              {this.label}
            </span>

            <div className={this.e('results')} key={`${id}-results`}>
              {focused &&
                suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? this.e('suggestion-item-active')
                    : this.e('suggestion-item')
                  return (
                    <div
                      key={`${suggestion?.placeId}-${i}`}
                      {...getSuggestionItemProps(suggestion, {
                        className
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
            </div>
            {this.error() && (
              <span
                key={`${id}-error`}
                className={this.e('error')}
                dangerouslySetInnerHTML={{ __html: this.error() }}
              />
            )}
          </label>
        )}
      </Autocomplete>
    )
  }
}

FormAddressAutocomplete.props(FormAddressAutocompleteModel)

export default connect(
  state => ({
    browser: state.self.browser,
    forms: state.forms
  }),
  { setFormFieldValues }
)(FormAddressAutocomplete)
