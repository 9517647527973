import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { withCookies } from 'react-cookie'
import { animated as a, useSpring } from '@react-spring/web'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import RichText from 'app/components/RichText/RichText'
import ProductOfferModel from 'app/models/ProductOffer/ProductOffer'
import PriceInformation from 'app/components/PriceInformation/PriceInformationNew'
import { ProductFeatureListItemModel } from 'app/components/ProductFeatureListItem/ProductFeatureListItem'
import ProductOfferSpecificationModel from 'app/models/ProductOfferSpecification/ProductOfferSpecification'

import ProductSpecList from './ProductSpecList'
import ProductFeatureList from './ProductFeatureList'

import './NewProductPlanCard.scss'

const _entryId = new WeakMap()

export class ProductPlanCardModel extends Model {
  constructor(d, parentName = false) {
    super(d)
    this.productOffer = new ProductOfferModel(d.productOffer)
    this.cta = new LinkModel(d.cta)

    // Computed
    this.analyticsEvent = this.cta.determineAnalyticsEvent()
    this.analyticsValue = parentName
      ? `${slug(parentName)}|${slug(d.name)}|${slug(this.cta.text)}`
      : `${slug(d.name)}|${slug(this.cta.text)}`

    // Private
    _entryId.set(this, d.entryId || false)
  }

  static props() {
    return {
      entryId: String,
      name: {
        type: String,
        required: true,
        default: ''
      },
      productOffer: {
        type: ProductOfferModel.shape.isRequired,
        default: ProductOfferModel.defaultProps()
      },
      featuresOrSpecs: {
        type: String,
        required: true,
        default: ''
      },
      featureSpecOverride: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape(ProductOfferSpecificationModel.shape),
          PropTypes.shape(ProductFeatureListItemModel.shape)
        ])
      ),
      cta: {
        type: LinkModel.shape.isRequired,
        default: LinkModel.defaultProps()
      },
      analyticsEvent: String,
      analyticsValue: String,
      isRecommended: {
        type: Boolean,
        default: false
      },
      untabbable: {
        type: Boolean,
        default: false
      },
      height: {
        type: Number,
        required: true,
        default: 815
      },
      moreDetailsButtonText: {
        type: String,
        default: 'Show details'
      },
      lessDetailsButtonText: {
        type: String,
        default: 'Hide details'
      },
      moreDetailsContent: {
        type: String,
        default: ''
      }
    }
  }
}

function NewProductPlanCard({
  productOffer,
  // isRecommended,
  moreDetailsContent,
  moreDetailsButtonText,
  lessDetailsButtonText,
  height,
  cookies,
  ...props
}) {
  const [recommended, setRecommended] = useState(false)
  const [pageName, setPageName] = useState('')
  useEffect(() => {
    const quizResults = cookies.get('quizResults')
    if (
      quizResults &&
      quizResults.canonicalProductOfferIds &&
      quizResults.canonicalProductOfferIds.includes(productOffer.entryId)
    ) {
      setRecommended(true)
    }
    if (typeof window !== 'undefined') {
      setPageName(window.location.pathname)
    }
  }, [])

  const [flipped, setFlipped] = useState(false)
  const { transform } = useSpring({
    transform: `rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  })
  const b = Bem('product-plan-card')
  const cta = new LinkModel(props.cta)

  const handleMoreInfoClick = () => {
    setFlipped(!flipped)
  }
  const offerSpecs = productOffer.offerSpecs
    ? productOffer.offerSpecs.reduce((acc, offer) => {
        if (offer && offer.name) {
          acc.push(offer)
        }
        return acc
      }, [])
    : []
  const offerFeatures = productOffer.offerFeatures
    ? productOffer.offerFeatures.reduce((acc, feature) => {
        if (feature && feature.name) {
          acc.push(feature)
        }
        return acc
      }, [])
    : []
  const headerVariation = productOffer.headerVariation || 'black'
  return (
    <div
      className={b.e('scene').m({ scotch: true }).classes()}
      style={{ height: height + 40 }}
    >
      <div>
        <a.div
          style={{ transform }}
          className={b.e('card-face').m({ front: true }).classes()}
        >
          {recommended && (
            <div className={b.e('recommended').classes()}>
              <span>{productOffer.pricingText.recommendedText}</span>
            </div>
          )}
          <div className={b.e('card').classes()} style={{ height }}>
            <div
              className={b
                .e('product-name')
                .m({ [headerVariation]: true })
                .classes()}
            >
              {productOffer.banner && (
                <div className={b.e('banner').classes()}>
                  <RichText markup={productOffer.banner} isMarkdown />
                </div>
              )}
              <RichText markup={productOffer.stylizedName} isMarkdown />
            </div>

            <PriceInformation isPromo productOffer={productOffer} />

            {offerSpecs.length > 0 && (
              <ProductSpecList items={productOffer.offerSpecs} />
            )}
            {offerFeatures.length > 0 && (
              <ProductFeatureList items={productOffer.offerFeatures} />
            )}
            <footer className={b.e('footer').classes()}>
              <PriceInformation productOffer={productOffer} />

              {props.cta && (
                <div className={b.e('button-container').classes()}>
                  <Button
                    className={b.e('cta').classes()}
                    design="scotch-primary"
                    variant="light"
                    analyticsEvent={props.analyticsEvent}
                    analyticsValue={`content|${slug(
                      pageName
                    )}|product-plan-cards|${slug(
                      productOffer.stylizedName
                    )}|${slug(cta.text)}`}
                    tabIndex={props.untabbable ? '-1' : '0'}
                    {...cta.asButton}
                  />
                </div>
              )}
              {moreDetailsContent && (
                <button
                  className={b.e('details-toggle').classes()}
                  onClick={handleMoreInfoClick}
                  data-event="navigationAction"
                  data-value={`content|${slug(
                    pageName
                  )}|product-plan-cards|${slug(
                    productOffer.stylizedName
                  )}|${slug(moreDetailsButtonText)}`}
                >
                  {moreDetailsButtonText}
                </button>
              )}
            </footer>
          </div>
        </a.div>

        {/* MORE INFO */}
        <a.div
          style={{
            transform: transform.to(t => `${t} rotateY(180deg)`)
          }}
          className={b.e('card-face').m({ back: true }).classes()}
        >
          {recommended && (
            <div className={b.e('recommended').classes()}>
              <span>{productOffer.pricingText.recommendedText}</span>
            </div>
          )}
          <div className={b.e('card').classes()} style={{ height }}>
            <div
              className={b
                .e('product-name')
                .m({ [headerVariation]: true })
                .classes()}
            >
              {productOffer.banner && (
                <div className={b.e('banner').classes()}>
                  <RichText markup={productOffer.banner} isMarkdown />
                </div>
              )}
              <RichText markup={productOffer.stylizedName} isMarkdown />
            </div>
            <div className={b.e('more-details').classes()}>
              <RichText
                markup={moreDetailsContent}
                renderers={{
                  root: 'div'
                }}
              />
            </div>
            <footer className={b.e('footer').classes()}>
              <div className={b.e('button-container').classes()}>
                <Button
                  className={b.e('cta').classes()}
                  design="scotch-primary"
                  variant="light"
                  analyticsEvent={props.analyticsEvent}
                  analyticsValue={`content|${slug(
                    pageName
                  )}|product-plan-cards|${slug(
                    productOffer.stylizedName
                  )}|${slug(cta.text)}`}
                  tabIndex={props.untabbable ? '-1' : '0'}
                  {...cta.asButton}
                />
              </div>
              <button
                className={b.e('details-toggle').classes()}
                onClick={handleMoreInfoClick}
                data-event="navigationAction"
                data-value={`content|${slug(
                  pageName
                )}|product-plan-cards|${slug(productOffer.stylizedName)}|${slug(
                  lessDetailsButtonText
                )}`}
              >
                {lessDetailsButtonText}
              </button>
            </footer>
          </div>
        </a.div>
      </div>
    </div>
  )
}
export default withCookies(NewProductPlanCard)
