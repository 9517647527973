import React from 'react'

import Slick from 'react-slick'
import PropTypes from 'prop-types'

import { v1 as uuid } from 'uuid'
import Bem from 'app/utils/bem-helper'
import {
  defaultCarouselOptions as defaultOptions,
  responsiveDefaultCarouselOptions as responsiveDefaultOptions
} from 'app/utils/constants'

import './Carousel.scss'

export class CarouselModel {
  constructor(opts = {}, breakpoints = {}) {
    this.options = opts
    this.small = breakpoints.small
    this.medium = breakpoints.medium
    this.large = breakpoints.large
    this.slick = React.createRef()
  }

  static propTypes() {
    return {
      className: PropTypes.string,
      options: PropTypes.shape(),
      small: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
      medium: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
      large: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
      onBreakpointChange: PropTypes.func
    }
  }

  static defaultProps() {
    return {
      className: '',
      options: {},
      small: {},
      medium: {},
      large: false,
      onBreakpointChange: () => {},
      onSlideChange: () => {}
    }
  }
}

/**
 * This component takes an options prop that corresponds directly to the React-Slick options with 1 exception.
 * Instead of using the responsive prop, use small, medium, and large to dictate options to apply to a specific
 * breakpoint.  If the carousel should un-carousel at a breakpoint, use 'false' as the prop (Boolean) for that
 * breakpoint, as opposed to an object.  Not specifying a breakpoint will use default options
 */
export default class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.b = Bem('carousel')
    Carousel.getOptions = Carousel.getOptions.bind(this)
    this.renderChildren = this.renderChildren.bind(this)
    // this.updateBreakpoint = this.updateBreakpoint.bind(this)
    this.afterChange = this.afterChange.bind(this)
    this.state = {
      breakpoint: null,
      currentIndex: 0
    }
  }

  afterChange(currentSlide) {
    this.setState({ currentIndex: currentSlide })
    this.props.onSlideChange(currentSlide)
  }

  /**
   * Merges default options, user specified options, builds responsive array based on predefined breakpoints,
   * disables when indicated.  Note that carousel is disabled on large screens by default.
   * @returns {object}
   */
  static getOptions(props = {}, afterChange = false) {
    // const sizes = Object.keys(breakpoints)
    const opts = {
      ...defaultOptions,
      responsive: responsiveDefaultOptions,
      ...props.options
    }

    if (afterChange) {
      opts.afterChange = afterChange
    }

    return opts
  }

  /**
   * Loop function to render children into slides with correct class and key prop
   * @param child
   * @param i
   * @returns {XML}
   */
  renderChildren(child) {
    return (
      <div className={this.b.e('slide').classes()} key={uuid()}>
        {child}
      </div>
    )
  }
  render() {
    const { children } = this.props

    return (
      <Slick
        className={this.b.and(this.props.className).classes()}
        ref={this.slick}
        {...Carousel.getOptions(this.props, this.afterChange)}
      >
        {children}
      </Slick>
    )
  }
}

Carousel.defaultProps = CarouselModel.defaultProps()
Carousel.propTypes = CarouselModel.propTypes()
