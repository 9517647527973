import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error('---------- error, errorInfo ----------->', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{ border: '2px solid red', margin: '15px', maxWidth: '100%' }}
        >
          <h3 style={{ fontSize: '16px', lineHeight: '1.6em', color: 'red' }}>
            Something went wrong rendering the component: <br />
            {this.props.type}.
          </h3>
          {this.props.id && process.env.NODE_ENV !== 'production' && (
            <p style={{ fontSize: '14px', marginTop: '15px' }}>
              Entry id: {this.props.id}
            </p>
          )}
        </div>
      )
    }

    return this.props.children
  }
}
