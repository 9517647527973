import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'

import './Notification.scss'

export class NotificationModel extends Model {
  static props() {
    return {
      name: {
        type: String,
        required: true
      },
      title: String,
      description: String,
      width: Number,
      background: { type: String, default: 'white' },
      margin: { type: String, default: 'none' }
    }
  }
}

export default function Notification({
  title,
  description,
  background,
  margin,
  width
}) {
  const b = Bem('notification')
  if (!title && !description) {
    return null
  }
  return (
    <div
      className={b
        .e('container')
        .m({
          [background]: true,
          [`margin-${margin}`]: true
        })
        .classes()}
    >
      <div
        className={b.classes()}
        style={width ? { maxWidth: `${width}px` } : {}}
      >
        {title && (
          <RichText className={b.e('title').classes()} markup={title} />
        )}
        {title && <div className={b.e('divider').classes()} />}
        {description && (
          <RichText
            className={b.e('description').classes()}
            markup={description}
          />
        )}
      </div>
    </div>
  )
}
