import React from 'react'

import RichText from 'app/components/RichText/RichText'

import './Eyebrow.scss'

export default function Eyebrow({ text, align = 'center', className = '' }) {
  if (!text) return null
  return (
    <RichText
      className={`eyebrow eyebrow--${align} ${className}`}
      markup={text}
      isMarkdown
      renderers={{ root: 'span' }}
    />
  )
}
