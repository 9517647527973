import React, { useId } from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import RichText from 'app/components/RichText/RichText'

import './SearchNoResults.scss'

// Module model definition
export class SearchNoResultsModel extends Model {
  static props() {
    return {
      title: {
        type: String,
        required: true
      },
      searchTerm: {
        type: String
      },
      description: String,
      list: Array
    }
  }
}

// Module definition
export default function SearchNoResults(props) {
  const b = Bem('search-no-results')
  return (
    <div className={b.and(props.className).c()}>
      <div className={b.e('container').c()}>
        <h1 className={b.e('title').c()}>
          <RichText markup={`${props.title}: “${props.searchTerm}”`} />
        </h1>
        <p className={b.e('description').c()}>{props.description}:</p>
        <ul className={b.e('list').c()}>
          {props.list &&
            props.list.map(item => {
              return (
                <li className={b.e('list-item').c()} key={useId()}>
                  {item}
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}
SearchNoResults.propTypes = SearchNoResultsModel.propTypes()
