import React from 'react'

import Bem from 'app/utils/bem-helper'
import Model from 'app/models/Model'
import { slug } from 'app/utils/paths'
import LinkModel from 'app/models/Link/Link'
import ImageModel from 'app/models/Image/Image'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import Eyebrow from 'app/components/Eyebrow/Eyebrow'
import RichText from 'app/components/RichText/RichText'

import './ImageTextBox.scss'

// Model definition
export class ImageTextBoxModel extends Model {
  static propTypes() {
    return {
      name: String,
      logo: ImageModel.shape,
      category: String,
      isFeatured: Boolean,
      eyebrow: String,
      title: String,
      description: String,
      imageAsset: ImageModel.shape,
      background: String,
      cta: LinkModel.shape
    }
  }
}

export default class ImageTextBox extends Component {
  constructor(props) {
    super(props, 'image-text-box')
  }

  render() {
    const { title, eyebrow, description, imageAsset, cta, background } =
      this.props
    const img = imageAsset?.large
      ? new ResponsiveImageModel(imageAsset)
      : new ImageModel(imageAsset)

    const link = new LinkModel(cta)
    const page =
      typeof window !== 'undefined' && location.pathname !== '/'
        ? `${slug(location.pathname)}|`
        : 'homepage|'
    const bgr =
      img?.src || img?.large?.src || img?.medium?.src || img?.small?.src

    const b = Bem('image-text-box')
    return (
      <div className={b.m({ [background]: true }).classes()}>
        <div className={this.e('container')}>
          <div className={this.e('content-box')}>
            {bgr && (
              <div
                className={this.e('image')}
                style={{ backgroundImage: `url(${bgr})` }}
                aria-label={img.description || ''}
              />
            )}
            <div className={this.e('content')}>
              {eyebrow && <Eyebrow align="left" text={eyebrow} />}
              {title && (
                <RichText
                  className={this.e('title')}
                  markup={title}
                  renderers={{
                    root: 'h3'
                  }}
                  data-testid="box-title"
                />
              )}
              {description && (
                <RichText
                  className={this.e('description')}
                  markup={description}
                  data-testid="box-description"
                />
              )}
              <Button
                type="link"
                {...link.asButton}
                analyticsEvent="navigationAction"
                analyticsValue={`${page}${slug(eyebrow)}|${slug(
                  link.accessibilityLabel || link.label
                )}`}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
