import React, { useEffect, useState } from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import Eyebrow from 'app/components/Eyebrow/Eyebrow'
import RichText from 'app/components/RichText/RichText'

import './Testimonial.scss'

// Component model definition
export class TestimonialModel extends Model {
  static props() {
    return {
      name: String,
      quote: {
        type: String,
        required: true,
        default:
          'This is the quote or testimonial that in some cases could be long'
      },
      author: {
        type: String,
        required: true,
        default: 'This is the author'
      }
    }
  }
}

// Component definition
function Testimonial({ quote, author }) {
  const [ssrDone, setSsrDone] = useState(false)
  const [fixQuote, setFixQuote] = useState(quote)

  useEffect(() => {
    const q = `<span className="testimonial__quote-left">“</span><span className="testimonial__quote-text">${quote}</span><span className="testimonial__quote-right">„</span>`
    setFixQuote(q)
  }, [ssrDone])

  useEffect(() => {
    setSsrDone(true)
  }, [])

  const b = Bem('testimonial')

  return (
    <div className={b.m({ scotch: true }).classes()}>
      <div className={b.e('content').classes()}>
        <div className={b.e('quote-container').classes()}>
          <RichText
            isMarkdown
            className={b.e('quote').classes()}
            markup={fixQuote}
            renderers={{ root: 'p' }}
          />
        </div>
        <Eyebrow text={author} />
      </div>
    </div>
  )
}

Testimonial.propTypes = TestimonialModel.propTypes()
export default Testimonial
