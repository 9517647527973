import React, { useState, useEffect } from 'react'
import { slug } from 'app/utils/paths'
import Icon from 'app/components/Icon/Icon'
import './Arrow.scss'

const Arrow = ({ name, icons, onClick, className }) => {
  const [pageName, setPageName] = useState('')
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPageName(window.location.pathname)
    }
  }, [])
  return (
    <button
      className={`slider-arrow slider-arrow--${slug(name)}
      ${className.includes('slick-disabled') && 'slider-arrow--disabled'}
      `}
      type="button"
      onClick={onClick}
      data-event="navigationAction"
      data-value={`content|${slug(pageName)}|product-plan-card-carousel|${slug(
        name
      )}`}
    >
      <Icon type={icons.chevronBlue} />
      {name}
    </button>
  )
}

export default Arrow
