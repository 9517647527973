import React from 'react'

import Model from 'app/models/Model'
import Component from 'app/components/Component'

import RichText from '../RichText/RichText'

import './RichTextBox.scss'

// Module model definition
export class RichTextBoxModel extends Model {
  static props() {
    return {
      name: String,
      title: String,
      description: String,
      background: String
    }
  }
}

// Module definition
export default class RichTextBox extends Component {
  constructor(props) {
    super(props, 'rich-text-box')
  }

  render() {
    const { title, description, background } = this.props
    return (
      <div className={this.b(background)}>
        <div className={this.e('container')}>
          <RichText
            markup={title}
            renderers={{ root: 'h1' }}
            data-testid="box-title"
          />
          <RichText markup={description} data-testid="box-description" />
        </div>
      </div>
    )
  }
}

RichTextBox.props(RichTextBoxModel)
