import React from 'react'
import Bem from 'app/utils/bem-helper'
import Model from 'app/models/Model'
import LinkModel from 'app/models/Link/Link'
import Button from 'app/components/Button/Button'
import Eyebrow from 'app/components/Eyebrow/Eyebrow'
import RichText from 'app/components/RichText/RichText'

import './VideoBox.scss'

export class VideoBoxModel extends Model {
  static props() {
    return {
      name: String,
      eyebrow: String,
      title: String,
      text: String,
      description: String,
      background: String,
      video: Object,
      cta: LinkModel.shape,
      ctaAlt: LinkModel.shape
    }
  }
}

export default function VideoBox({
  eyebrow,
  title,
  description,
  video,
  cta,
  ctaAlt,
  background = ''
}) {
  const b = Bem('video-box')
  const button = cta ? new LinkModel(cta) : null
  const button2 = ctaAlt ? new LinkModel(ctaAlt) : null
  return (
    <div className={b.m({ [background]: true }).classes()}>
      {eyebrow && (
        <Eyebrow className={b.e('eyebrow').classes()} text={eyebrow} />
      )}
      {title && (
        <RichText
          className={b.e('title').classes()}
          markup={title}
          renderers={{ root: 'h2' }}
          data-testid="box-title"
        />
      )}
      {description && (
        <RichText
          className={b.e('description').classes()}
          markup={description}
          data-testid="box-description"
        />
      )}
      {video && video.file && (
        <video
          className={b.e('video').classes()}
          preload="auto"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
          id="superHero-video-bg"
          poster={video.poster}
        >
          <source src={video?.file?.url} type={video?.file?.contentType} />

          <track
            src={video?.track}
            kind="captions"
            srcLang="en"
            label="English"
          />
        </video>
      )}
      <div className={b.e('buttons').classes()}>
        {button && (
          <Button
            text={button.text}
            link={button}
            className={b.e('button').classes()}
          />
        )}
        {button2 && (
          <Button
            text={button2.text}
            link={button2}
            className={b.e('button').classes()}
          />
        )}
      </div>
    </div>
  )
}
