import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import Model from 'app/models/Model'
import Icon from 'app/components/Icon/Icon'
import { icons } from 'app/utils/constants'
import Component from 'app/components/Component'
import { setFormFieldValues } from 'app/actions/form'

// Component model definition
export class FormDropdownModel extends Model {
  static props() {
    return {
      id: String,
      label: String,
      list: Array,
      required: {
        type: Boolean,
        default: false
      },
      requiredError: String,
      form: Object,
      onFormChange: {
        type: Function,
        default: () => {}
      }
    }
  }
}

// Component definition
class FormDropdown extends Component {
  constructor(props) {
    super(props, 'form-field')

    if (this.props.required) {
      this.props.form.require(this.props.id)
    }

    if (this.props.requiredError) {
      this.props.form.messages.add(
        this.props.id,
        'required',
        this.props.requiredError
      )
    }

    this.formName = 'form'

    this.state = {
      focused: false,
      value: this.props.form.reset(this.props.id, '')
    }

    this.props.setFormFieldValues(this.formName, this.props.id, '')
  }

  error = () => {
    return this.props.form.errors.first(this.props.id) || ''
  }

  getFieldValue = () => {
    // return this.state.value
    return this.props.forms[this.formName]
      ? this.props.forms[this.formName][this.props.id]
      : null
  }

  onFocus = () => {
    this.props.form.invalidate(this.props.id)
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.props.form.validate(this.props.id)
    this.setState({ focused: false })
    this.props.onFormChange()
  }

  onChange = evnt => {
    const value = evnt.target.value
    this.props.setFormFieldValues(this.formName, this.props.id, value)
    this.props.form.set(this.props.id, value)
    this.setState({ value })
    this.props.onFormChange()
  }

  renderOption = (option, index) => {
    let value = ''
    if (typeof option === 'string') {
      value = option
    } else {
      value = option?.value
    }
    return (
      <option value={value} key={index}>
        {option.label || option}
      </option>
    )
  }

  render() {
    return (
      <Fragment>
        <label
          className={this.e('select-label', {
            focus: this.state.focused,
            filled: !!this.state.value,
            active: this.state.focused || !this.props.form.empty(this.props.id)
          })}
          id={`${this.props.id}-label`}
          htmlFor={this.props.id}
        >
          <select
            id={this.props.id}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            value={this.getFieldValue()}
          >
            {this.renderOption({ label: this.props.label, value: '' })}
            {this.props.list.map(this.renderOption)}
          </select>
        </label>

        {this.error() && (
          <span
            className={this.e('error')}
            dangerouslySetInnerHTML={{ __html: this.error() }}
          />
        )}
      </Fragment>
    )
  }
}

FormDropdown.props(FormDropdownModel)

export default connect(
  state => ({
    browser: state.self.browser,
    forms: state.forms
  }),
  { setFormFieldValues }
)(FormDropdown)
