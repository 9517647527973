import React from 'react'

import { v1 as uuid } from 'uuid'
import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import { slug } from 'app/utils/paths'
import Icon from 'app/components/Icon/Icon'
import LinkModel from 'app/models/Link/Link'
import Component from 'app/components/Component'
import Button from 'app/components/Button/Button'
import RichText, { RichTextModel } from 'app/components/RichText/RichText'

import './ItemsListBox.scss'

// Component model definition
export class ItemsListBoxModel extends Model {
  constructor(d) {
    super(d)
    const castLinkList = item => new LinkModel(item)
    this.link = ((d && d.link) || []).map(castLinkList)
    this.cta = new LinkModel(d.cta)
  }

  static props() {
    return {
      name: String,
      title: String,
      link: LinkModel.arrayOf,
      cta: LinkModel.shape,
      parentNodeMame: String
    }
  }
}

// Component definition
export default class ItemsListBox extends Component {
  constructor(props) {
    super(props)
    this.b = Bem('items-list-box')
  }

  m() {
    return {
      scotch: true
    }
  }

  render() {
    const title = new RichTextModel(this.props.title)
    return (
      <div
        className={this.b
          .and(`${this.props.className} items-list-box--scotch`)
          .classes()}
      >
        <div className={this.b.e('icon').classes()}>
          <Icon {...this.props.icon} />
        </div>
        <RichText className={this.b.e('title').classes()} {...title} />
        <div>{this.renderLinks({ ...this.props.link })}</div>
        {this.renderButton()}
      </div>
    )
  }

  renderLinks(linkList) {
    const linksArray = []
    Object.keys(linkList).forEach(function (key) {
      linksArray.push(linkList[key])
    })

    return linksArray.map(link => {
      const button = link
        ? {
            ...link.asButton,
            type: 'link',
            design: 'link',
            link: link.href,
            text: link.label,
            analyticsEvent: 'navigationAction',
            analyticsValue: `${slug(this.props.parentNodeMame)}|${slug(
              this.props.title
            )}|${slug(link.label)}`,
            target:
              link.href && link.href.includes('https://') ? '_blank' : '_self'
          }
        : null

      return button ? (
        <div key={uuid()} className={this.b.e('link').classes()}>
          <Button {...button} />
        </div>
      ) : null
    })
  }

  renderButton() {
    const { cta, buttonStyle } = this.props
    if (!cta) {
      return null
    }

    const ctaLink = new LinkModel(cta)

    const button = {
      ...ctaLink.asButton,
      type: 'link',
      design: cta.style || buttonStyle || 'primary',
      analyticsEvent: ctaLink.determineAnalyticsEvent(),
      analyticsValue: `${slug(this.props.parentNodeMame)}|${slug(
        this.props.title
      )}|${slug(cta.label)}`
    }

    return (
      <div className={this.b.e('cta-container').classes()}>
        <Button {...button} className={this.b.e('cta-button').classes()} />
      </div>
    )
  }
}

ItemsListBox.propTypes = ItemsListBoxModel.propTypes()
ItemsListBox.defaultProps = ItemsListBoxModel.defaultProps()
