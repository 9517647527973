import React from 'react'

import { v1 as uuid } from 'uuid'
import Component from 'app/components/Component'
import { breakpoints } from 'app/utils/constants'
import ResponsiveImage, {
  ResponsiveImageModel
} from 'app/components/ResponsiveImage/ResponsiveImage'

import './ImageSwitcher.scss'
// Component definition
export default class ImageSwitcher extends Component {
  constructor(props) {
    super(props, 'image-switcher')
    this.state = {}
  }

  componentDidMount() {
    this.onImageChange(0)
    if (global && global.addEventListener) {
      global.addEventListener('resize', this.onResize)
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.current !== this.props.current) {
      this.onImageChange(nextProps.current)
    }
  }

  onImageChange(index) {
    if (global && global.addEventListener) {
      const width =
        global.innerWidth ||
        global.document.documentElement.clientWidth ||
        global.document.body.clientWidth

      let currentImageWithSize
      let currentImage = this.props.images[index]
      for (const size in breakpoints) {
        if (width >= breakpoints[size].min && width <= breakpoints[size].max) {
          currentImageWithSize = currentImage[size]
          break
        }
      }

      if (!currentImageWithSize) {
        currentImageWithSize =
          currentImage['small'] ||
          currentImage['medium'] ||
          currentImage['large']
      } else {
        this.setState({
          height: currentImageWithSize.file.details.image.height
        })
      }
    }
  }

  onResize() {
    this.onImageChange(this.props.current)
  }

  renderImage(image, i) {
    return (
      <ResponsiveImage
        className={this.e('image', { selected: i === this.props.current })}
        key={uuid(i)}
        aria-hidden={i !== this.props.current}
        {...image}
      />
    )
  }

  render() {
    const { images } = this.props
    const style = {
      height: `${this.state.height}px`
    }

    return (
      <div className={this.b()} style={style}>
        {images.map(this.renderImage)}
      </div>
    )
  }
}

ImageSwitcher.props({
  images: ResponsiveImageModel.arrayOf.isRequired,
  current: {
    type: Number,
    default: 0
  }
})
