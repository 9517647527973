import React from 'react'

import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import Component from 'app/components/Component'
import FormSet from 'app/components/FormSet/FormSet'
import Checkbox from 'app/components/Checkbox/Checkbox'

import './ProductGroup.scss'
export class ProductGroupModel extends Model {
  static props() {
    return {
      id: String,
      individual: Boolean,
      columns: Number,
      checkboxes: Array,
      form: Object,
      onFormChange: {
        type: Function,
        default: () => {}
      }
    }
  }
}

// Component definition
class ProductGroup extends Component {
  constructor(props) {
    super(props, 'product-group')
    this.b = Bem('product-group')
    this.state = { ssrDone: false }
    const paramValue =
      (this.props.urlParams && this.props.urlParams[this.props.id]) || ''
    const paramValues = paramValue.split('|')
    if (!props.individual) {
      const values =
        props.checkboxes &&
        props.checkboxes.reduce((vals, checkbox) => {
          const selected = paramValues.indexOf(checkbox.id) !== -1
          vals[checkbox.id] = selected
          return vals
        }, {})
      this.state = {
        values,
        ssrDone: false
      }
      this.props.form.set(this.props.id, this.values())
    }
  }

  componentDidMount() {
    const { urlParams, checkboxes, id, setVisibility } = this.props
    const paramValue = (urlParams && urlParams[id]) || ''
    const paramValues = paramValue.split('|')
    const chks =
      checkboxes &&
      checkboxes.filter(f => {
        return paramValues.indexOf(f.id) !== -1
      })
    if (chks && chks.length === 0) {
      setVisibility(false)
    }
    this.setState({ ssrDone: true })
  }

  values() {
    const { values } = this.state
    return (
      values &&
      Object.keys(values).filter(key => {
        return values[key]
      })
    )
  }

  handleCheckboxChange(name, val) {
    this.setState({
      values: {
        ...this.state.values,
        [name]: val
      }
    })

    setTimeout(() => {
      this.props.form.set(this.props.id, this.values())
      this.props.onFormChange()
    }, 0)
  }

  renderCheckbox(checkbox, key) {
    if (this.state != null) {
      if (!this.props.individual) {
        if (this.state.values[checkbox.id] === true) {
          checkbox.initial = true
        }
      } else {
        if (this.state.values.indexOf(checkbox.id) !== -1) {
          checkbox.initial = true
        }
      }
    }

    return (
      <Checkbox
        {...checkbox}
        name={this.props.individual ? checkbox.id : this.props.id}
        className={this.e('checkbox')}
        form={this.props.form}
        onChange={this.props.individual ? undefined : this.handleCheckboxChange}
        aria-label={this.props.id}
        // skipInitializeValue
        key={key}
      />
    )
  }

  render() {
    const { urlParams, checkboxes, columns, id } = this.props
    const paramValue = (urlParams && urlParams[this.props.id]) || ''
    const paramValues = paramValue.split('|')
    const { ssrDone } = this.state
    const chks =
      checkboxes &&
      checkboxes
        .filter(f => {
          return paramValues.indexOf(f.id) !== -1
        })
        .map(r => {
          return {
            ...r,
            form: this.props.form,
            contentType: 'Checkbox',
            entryId: r.id
          }
        })

    return ssrDone ? (
      <FormSet
        className={super.b()}
        columns={columns}
        id={id}
        items={chks}
        render={this.renderCheckbox}
        role="group"
      />
    ) : null
  }
}

ProductGroup.props(ProductGroupModel)

const mapStateToProps = ({ self }) => ({
  urlParams: self.query
})

export default connect(mapStateToProps, null)(withCookies(ProductGroup))
