import React from 'react'

import Model from 'app/models/Model'
import Component from 'app/components/Component'
import RichText from 'app/components/RichText/RichText'

// Component model definition
export class RadioButtonModel extends Model {
  static props() {
    return {
      fieldName: String,
      entryId: String,
      onChange: {
        type: Function,
        default: () => {}
      },
      onFocus: {
        type: Function,
        default: () => {}
      },
      onBlur: {
        type: Function,
        default: () => {}
      },
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      value: [String, Boolean],
      selected: {
        type: Boolean,
        required: false
      }
    }
  }
}

// Component definition
export default class RadioButton extends Component {
  constructor(props) {
    super(props, 'radio-group')
    this.state = {
      hasFocus: false,
      showAltContent: false
    }
  }

  handleFocus() {
    this.setState({ hasFocus: true })
  }

  handleBlur() {
    this.setState({ hasFocus: false })
    this.props.onBlur()
  }

  m() {
    return {
      'has-focus': this.state.hasFocus
    }
  }

  render() {
    const { id, fieldName, label, value, checked, onChange } = this.props
    return (
      <p className={this.b()} aria-checked={checked} aria-label={label}>
        <label className={this.e('label', { checked: checked })} htmlFor={id}>
          <RichText markup={label} renderers={{ root: 'span' }} />
          <input
            className={this.e('input')}
            type="radio"
            checked={checked}
            id={id}
            name={fieldName}
            value={value}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            data-testid="radio-button"
          />
        </label>
      </p>
    )
  }
}

RadioButton.props(RadioButtonModel)
