import React from 'react'

import Model from 'app/models/Model'
import Bem from 'app/utils/bem-helper'
import BasicTextBox, {
  BasicTextBoxModel
} from 'app/components/BasicTextBox/BasicTextBox'
import ResponsiveImage, {
  ResponsiveImageModel
} from 'app/components/ResponsiveImage/ResponsiveImage'

import './HardwareFeatureListItem.scss'

// Component model definition
export class HardwareFeatureListItemModel extends Model {
  static props() {
    return {
      title: String,
      image: {
        type: ResponsiveImageModel.shape,
        required: true,
        default: ResponsiveImageModel.defaultProps()
      },
      description: {
        type: BasicTextBoxModel.shape,
        required: true,
        default: new BasicTextBoxModel({
          title: 'Hardware Feature title',
          description: 'Hardware Feature description'
        })
      },
      columns: BasicTextBoxModel.arrayOf
    }
  }
}

// Component definition
export default function HardwareFeatureListItem(
  props = HardwareFeatureListItemModel.defaultProps()
) {
  const b = Bem('hardware-feature-item')
  const image = new ResponsiveImageModel(props.image)

  return (
    <li className={b.and(props.className).classes()}>
      <div className={b.e('main-container').classes()}>
        <div className={b.e('image-container').classes()}>
          <ResponsiveImage {...image} alt={image.currentImage.description} />
        </div>
        <div className={b.e('description-container').classes()}>
          <span className={b.e('title').classes()}>{props.title}</span>
          <BasicTextBox
            className={b.e('description').classes()}
            {...props.description}
          />
        </div>
      </div>
      {props.columns && (
        <div className={b.e('secondary-container').classes()}>
          {props.columns.map((textBox, idx) => (
            <BasicTextBox
              key={idx}
              className={b.e('secondary-text-box').classes()}
              {...textBox}
            />
          ))}
        </div>
      )}
    </li>
  )
}

HardwareFeatureListItem.propTypes = HardwareFeatureListItemModel.propTypes()
