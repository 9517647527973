import React, { useEffect, useState } from 'react'

import Bem from 'react-bem-helper'
import { withCookies } from 'react-cookie'
import { animated, useSpring } from '@react-spring/web'

import Button from 'app/components/Button/Button'

import RegionSelectorButtonComp from './RegionSelectorButton'
import { getLocationQuery, getValidProvinces } from './constants'

import './RegionSelector.scss'

export const missing = `Unfortunately, we couldn't detect your location. For accurate pricing information, please select your region below:`
export const found = `For accurate pricing information, please select your region below:`

export function RegionSelector({
  isOpen = null,
  background,
  showCta = true,
  provinces,
  id,
  cookies,
  callback,
  isLoadingProp,
  provinceProp = ''
}) {
  const [isOpenState, setIsOpenState] = useState(isOpen)
  const [isLoading, setIsLoading] = useState(isLoadingProp)
  const [province, setProvince] = useState(provinceProp)
  const props = useSpring({
    to: { height: isOpenState ? 'auto' : 0, opacity: isOpenState ? 1 : 0 },
    from: { height: 0, opacity: 0 },
    immediate: true,
    delay: 1
  })
  const buttonProps = {
    design: 'scotch-primary',
    variant: 'light'
  }
  const classes = new Bem({
    name: 'region-selector',
    prefix: ''
  })
  useEffect(() => {
    setTimeout(() => {
      setProvince(getLocationQuery(cookies, true))
    }, 1000)
  }, [])

  useEffect(() => {
    if (isOpen !== null && isOpen !== isOpenState) {
      setIsOpenState(!isOpenState)
    }
  }, [isOpen])

  const toggleLocationSelector = () => {
    setIsOpenState(!isOpenState)
  }

  const handleChange = e => {
    setProvince(e.target.value)
    window && window.utag && window.utag.view({ province: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!window) return
    const value = province

    const cookieOptions = { path: '/', maxAge: 2592000 } // 30days
    const query = window.location.search
    const newQuery = query.replace(/(^\?|&)location+([a-zA-Z=]{3})/g, '')
    callback()
    setIsLoading(true)
    if (value && cookies) {
      cookies.set('queryLocation', value, cookieOptions)
      window.location.replace(
        `${window.location.origin}${window.location.pathname}${newQuery}`
      )
    }
  }

  const validProvinces = getValidProvinces(provinces)
  const renderMessage = () => {
    return (
      <p {...classes({ element: 'info' })} data-testid="region-selector-info">
        {!province ? missing : found}
      </p>
    )
  }

  return (
    <div
      {...classes({ modifier: ['scotch'] })}
      style={{ backgroundColor: background }}
    >
      <div {...classes({ element: 'container' })}>
        {showCta && (
          <RegionSelectorButtonComp
            handleClick={toggleLocationSelector}
            isLoading={isLoading}
            isOpen={isOpenState}
            data-testid="region-selector-button"
          />
        )}
        <animated.form
          style={props}
          {...classes({ element: 'location-selector' })}
          onSubmit={handleSubmit}
        >
          {renderMessage()}
          <div {...classes({ element: 'selector' })}>
            <label htmlFor={id || 'region-selector-dropdown'}>
              <strong {...classes({ element: 'label' })}>
                Available Regions:
              </strong>
            </label>
            <div {...classes({ element: 'options' })}>
              <select
                id={id || 'region-selector-dropdown'}
                data-testid="region-selector-dropdown"
                className="select-css"
                onChange={handleChange}
                onBlur={() => {}}
                value={province || ''}
                name="location"
              >
                <option>Select a province</option>
                {validProvinces.map(province => (
                  <option key={province.value} value={province.value}>
                    {province.label}
                  </option>
                ))}
              </select>
              <Button text="Update" type="submit" {...buttonProps} />
            </div>
          </div>
        </animated.form>
      </div>
    </div>
  )
}


export default withCookies(RegionSelector)
