import React from 'react'

import PropTypes from 'prop-types'

import Bem from 'app/utils/bem-helper'
import { ResponsiveImageModel } from 'app/components/ResponsiveImage/ResponsiveImage'

import './FullBleedBackground.scss'

// Component model definition
export class FullBleedBackgroundModel {
  static propTypes() {
    return {
      className: PropTypes.string,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ]),
      image: ResponsiveImageModel.shape.isRequired,
      id: PropTypes.string
    }
  }

  static defaultProps() {
    return {
      image: ResponsiveImageModel.defaultProps()
    }
  }
}

// Component definition
export default class FullBleedBackground extends React.Component {
  constructor(props) {
    super(props)
    this.b = Bem('full-bleed-background')
    this.onResize = this.onResize.bind(this)
    this.state = {
      currentImage: null
    }
  }
  componentDidMount() {
    if (global && global.addEventListener) {
      global.addEventListener('resize', this.onResize)
    }
  }

  componentWillUnmount() {
    if (global && global.removeEventListener) {
      global.removeEventListener('resize', this.onResize)
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const responsiveImage = new ResponsiveImageModel(nextProps.image)
    return { currentImage: responsiveImage.currentImage }
  }

  onResize() {
    const responsiveImage = new ResponsiveImageModel(this.props.image)
    this.setState({ currentImage: responsiveImage.currentImage })
  }

  render() {
    // We could be doing more for accessibility here
    const { children } = this.props
    const backgroundStyle = {
      backgroundImage: `url(${this.state.currentImage.src})`
    }

    return (
      <div
        className={this.b.and().classes()}
        aria-label={this.state.currentImage.description}
        data-entry-id={this.props.id}
        style={backgroundStyle}
      >
        {children}
      </div>
    )
  }
}

FullBleedBackground.propTypes = FullBleedBackgroundModel.propTypes()
FullBleedBackground.defaultProps = FullBleedBackgroundModel.defaultProps()
