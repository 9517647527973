import React, { Fragment } from 'react'

import { format } from 'date-fns'
import { Tooltip } from 'react-tooltip'

import Model from 'app/models/Model'

import { slug } from 'app/utils/paths'
import Icon from 'app/components/Icon/Icon'
import Link from 'app/components/Link/Link'
import LinkModel from 'app/models/Link/Link'

import Component from 'app/components/Component'
import { useChat } from 'app/modules/ChatContext'
import { canUseDOM, isLarge } from 'app/utils/env-helpers'

import './ChatBar.scss'

const regexBr = /<br\s*[\/]?>/gi

const formatTime = hour => {
  const date = format(new Date(), 'yyyy-MM-dd')
  const custDate = new Date(`${date} ${hour}`)
  return format(custDate, 'h:mm a')
}
export class ChatBarModel extends Model {
  static props() {
    return {
      links: LinkModel.arrayOf,
      onOpen: { type: Function, default: () => {} },
      onClose: { type: Function, default: () => {} }
    }
  }
}

function ChatButton({ link, className, analyticsValue }) {
  const { liveagent, chatAvailable } = useChat()
  const getChatButtonId = s => {
    var m = new RegExp(/#chat:([^:]+):([^:]+)(:([^:]+))?$/).exec(s)
    return m != null
      ? {
          buttonId: m[2],
          linkInstanceId: m[1],
          hideOnOnline: m[4] === 'offline',
          hideOnOffline: m[4] === 'online'
        }
      : null
  }

  const buttonData = getChatButtonId(link.href)

  const handleChat = e => {
    e.preventDefault()
    if (chatAvailable && typeof window !== 'undefined') {
      const href = link.href
      try {
        const b = getChatButtonId(href)
        liveagent.startChat(b.buttonId)
      } catch (error) {
        if (window?.LOG_LEVEL?.match(/info|verbose|debug|silly/)) {
          console.log(
            '------- chat start error --------->',
            error,
            '\n',
            getChatButtonId(href)
          )
        }
      }
    }
  }

  const am =
    typeof window !== 'undefined'
      ? formatTime(window.chatConfig.CHAT_BUSINESS_HOURS_START)
      : formatTime(process.env.CHAT_BUSINESS_HOURS_START)
  const pm =
    typeof window !== 'undefined'
      ? formatTime(window.chatConfig.CHAT_BUSINESS_HOURS_END)
      : formatTime(process.env.CHAT_BUSINESS_HOURS_END)

  const title = link.tooltip
  const chatHours = title
    ? title.replace('{{am}}', am).replace('{{pm}}', pm)
    : ''
  const chatTitle = chatHours.replace(regexBr, '')
  const hideButton = chatAvailable
    ? buttonData.hideOnOnline
    : buttonData.hideOnOffline
  const isOffline = link.href.includes(':offline')
  const chatProps = isOffline
    ? { 'data-tooltip-id': getChatButtonId(link.href).buttonId }
    : {}
  return (
    <Fragment>
      <Link
        link={link}
        id={getChatButtonId(link.href).buttonId}
        title={chatTitle}
        onClick={handleChat}
        className={`${className || ''} ${hideButton ? 'hide' : 'show'}`}
        analyticsValue={analyticsValue}
        analyticsEvent="chatAction"
        {...chatProps}
      />
      {isOffline && (
        <Tooltip
          id={getChatButtonId(link.href).buttonId}
          place="bottom"
          className="chat-tooltip"
          classNameArrow="chat-tooltip-arrow"
        >
          <p className="chat-tooltip-title">
            <strong>{link.text}</strong>
          </p>
          <p
            className="chat-tooltip-content"
            dangerouslySetInnerHTML={{
              __html: chatHours
            }}
          />
        </Tooltip>
      )}
    </Fragment>
  )
}

function ChatLink({ name, title, link, className, onMouseOver }) {
  return (
    <li className={className}>
      <Link
        analyticsValue={`${slug(name)}|chat-bar|${slug(link.ariaLabel)}`}
        link={link}
        title={title}
        onMouseOver={onMouseOver}
      />
    </li>
  )
}

class ChatBar extends Component {
  constructor(props) {
    super(props, 'chat-bar')

    this.state = {
      opened: false
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleBodyScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleBodyScroll)
  }

  m() {
    return {
      opened: this.state.opened
    }
  }

  handleBodyScroll = () => {
    this.closeContainer()
  }

  closeContainer = () => {
    this.setState({ opened: false })
    this.props.onClose()
  }

  openContainer = () => {
    this.setState({ opened: true })
    this.props.onOpen()
  }

  buttonHandler = event => {
    event.preventDefault()
    event.stopPropagation()
    this.openContainer()
  }

  closeButtonHandler = event => {
    event.preventDefault()
    event.stopPropagation()
    this.closeContainer()
  }

  magnifyGlassHasValue = () => {
    return !this.largeScreen() && this.state.opened
  }

  transitionEndHandler = () => {
    if (!this.state.opened) {
      this.setState({ searchTerm: '' })
    }
  }

  largeScreen = () => {
    return canUseDOM() && isLarge()
  }

  renderLinks(links) {
    if (!links) {
      return null
    }

    return (
      <ul className={this.e('links', !this.state.opened ? 'hidden' : '')}>
        {links.map((link, i) => (
          <ChatLink
            key={`${i}-${slug(link.ariaLabel)}`}
            link={link}
            name={this.props.name}
            className={this.e('link')}
          />
        ))}
      </ul>
    )
  }

  render() {
    return (
      <div>
        <div
          className={this.b()}
          onTransitionEnd={this.transitionEndHandler}
          ref={this.chatBarRef}
        >
          <button
            className={this.e('button')}
            onClick={this.buttonHandler}
            data-event="navigationAction"
            data-value="global-nav|open-chat-bar"
            data-testid="open-chat"
          >
            <Icon className={this.e('icon')} type="Nav_Chat" />
            <span className={this.e('label')}>Chat</span>
          </button>
          <ul className={this.e('input-container')}>
            {this.props.links.map((link, i) => {
              if (link.href && link.href.includes('#chat')) {
                return (
                  <li
                    className={this.e('link')}
                    key={`${i}-${slug(link.ariaLabel)}`}
                  >
                    <ChatButton
                      link={link}
                      analyticsValue={`${slug(this.props.name)}|chat-bar|${slug(
                        link.ariaLabel
                      )}`}
                    />
                  </li>
                )
              }
              return (
                <li
                  className={this.e('link')}
                  key={`${i}-${slug(link.ariaLabel)}`}
                >
                  <Link
                    analyticsValue={`${slug(this.props.name)}|chat-bar|${slug(
                      link.ariaLabel
                    )}`}
                    link={link}
                  />
                </li>
              )
            })}
          </ul>
          <button
            data-testid="close-chat"
            type="reset"
            aria-label="Close Chat"
            className={this.e('close-button')}
            onClick={this.closeButtonHandler}
            disabled={!this.state.opened}
          />
        </div>
      </div>
    )
  }
}

ChatBar.props(ChatBarModel)
export default ChatBar
